import React from "react";
import classes from "./Tab.css";
import { NavLink } from "react-router-dom";

const tab = props => (
    <NavLink
        to={props.link}
        activeClassName={classes.Active}
        className={classes.Tab}
    >
        {props.children}
    </NavLink>
);

export default tab;
