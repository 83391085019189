import React from "react";
import classes from "./SearchBox.css";
import FontAwesome from "react-fontawesome";
import { withI18n } from "react-i18next";

const searchBox = props => {
    const { t } = props;
    return (
        <div className={classes.SearchBox}>
            <div className={classes.Container}>
                <span className={classes.Icon}>
                    <FontAwesome name="search" />
                </span>
                <input
                    type="search"
                    className={classes.Input}
                    value={props.value}
                    onChange={props.filter}
                    placeholder={t("search_placeholder")}
                />
            </div>
        </div>
    );
};

export default withI18n()(searchBox);
