import React from "react";
import classes from "./NumberStats.css";

const numberStats = props => (
    <div
        className={[classes.NumberStats, props.small ? classes.Small : []].join(
            " "
        )}
    >
        {props.children}
    </div>
);

export default numberStats;
