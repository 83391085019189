import React from 'react';

const staticCard = (props) => {
    return(
        <div className={props.position}>
            <span>{props.digit}</span>
        </div>
    );
};

export default staticCard;