import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import MetaTags from "react-meta-tags";
import classNames from "classnames";

import classes from "./FestivalList.css";
import Festival from "./Festival/Festival";
import * as actions from "../../store/actions/index";
import { withI18n } from "react-i18next";

class FestivalList extends Component {
    componentDidMount() {
        this.props.loadFestivalList();
        this.props.exitFestival();
    }

    festivalSelectedHandler = festivalInfo => {
        this.props.selectFestival(festivalInfo);
        this.props.history.push({
            pathname:
                "/festival/" +
                festivalInfo.id +
                (festivalInfo.status === "countdown" ? "/info" : "/ranking")
        });
    };

    renderLoader() {
        return (
            <React.Fragment>
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader1,
                        classes.Highlight
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader1
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader2
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader2
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader1
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader1
                    )}
                />
                <div
                    className={classNames(
                        classes.FestivalLoader,
                        classes.FestivalLoader2
                    )}
                />
            </React.Fragment>
        );
    }

    render() {
        const { t } = this.props;
        let festlist = this.renderLoader();

        if (this.props.festivals) {
            let size = this.props.festivals.length;
            if (size > 19) {
                size = 19;
            } else {
                // Prevents single event in a column
                if (size % 2 === 0) size--;
            }
            festlist = this.props.festivals
                .slice(0, size)
                .map((festival, index) => {
                    return (
                        <Festival
                            clicked={() =>
                                this.festivalSelectedHandler(festival)
                            }
                            key={festival.id}
                            name={festival.name}
                            location={
                                festival.location +
                                ", " +
                                t("country_" + festival.country)
                            }
                            live={festival.status === "in_progress"}
                            highlight={index === 0}
                            startDate={festival.start_date}
                            endDate={festival.end_date}
                            img={festival.img}
                        />
                    );
                });
        }

        let year = moment().format("YYYY");
        let next_year = moment()
            .add(6, "months")
            .format("YYYY");
        if (year !== next_year) {
            year += "/" + next_year;
        }

        return (
            <div className={classes.FestivalList}>
                <MetaTags>
                    <title>
                        {t("festival_list_title", { year })} | Brewer Stage
                    </title>
                    <meta
                        name="description"
                        content={t("festival_list_desc", { year })}
                    />
                </MetaTags>
                {festlist}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        festivals: state.festivalList.festivals
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadFestivalList: () => dispatch(actions.loadFestivalList()),
        selectFestival: festivalInfo =>
            dispatch(actions.selectFestival(festivalInfo)),
        exitFestival: () => dispatch(actions.exitFestival())
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FestivalList)
);
