import * as JsSearch from "js-search";

import AccentFoldingSanitizer from "../../util/AccentFoldingSanitizer";
import * as actionTypes from "../actions/actionTypes";
import i18n from "i18next";

const initialState = {
    currentFestival: null,
    currentFestivalConfig: null,
    startTime: null,
    beerRanking: null,
    filteredBeerRanking: null,
    styleFilteredBeerRanking: null,
    rankingIndex: null,
    searchTerm: "",
    breweryRanking: null,
    stats: null,
    users: null,
    styles: null,
    styleGroups: null,
    hasNewReleases: false,
    selectedStyle: "",
    breweryLineup: null,
    beerList: null
};

const setFestivalConfig = (state, action) => {
    return {
        ...state,
        currentFestivalConfig: action.festivalConfig
    };
};

const setBeerRanking = (state, action) => {
    if (action.ranking) {
        const rankingIndex = new JsSearch.Search("beer_id");
        rankingIndex.searchIndex = new JsSearch.UnorderedSearchIndex();
        rankingIndex.sanitizer = new AccentFoldingSanitizer();
        rankingIndex.addIndex("beer");
        rankingIndex.addIndex("brewery");
        rankingIndex.addIndex("style");
        rankingIndex.addDocuments(action.ranking);
        let hasNewReleases = false;
        // Filter by style
        let sfRanking = action.ranking;
        if (state.selectedStyle === i18n.t("new_releases")) {
            sfRanking = state.beerRanking.filter(beer => beer.new_release);
            let pos = 1;
            sfRanking.map(beer => {
                return (beer.styleRankingPosition = pos++);
            });
            return {
                ...state,
                beerRanking: action.ranking,
                filteredBeerRanking: action.ranking,
                styleFilteredBeerRanking: sfRanking,
                rankingIndex: rankingIndex,
                hasNewReleases: true
            };
        } else if (
            state.selectedStyle &&
            state.styleGroups &&
            Object.keys(state.styleGroups).includes(state.selectedStyle)
        ) {
            sfRanking = sfRanking.filter(beer => {
                if (beer.new_release) {
                    hasNewReleases = true;
                }
                return state.styleGroups[state.selectedStyle].includes(
                    beer.style
                );
            });
            let pos = 1;
            sfRanking.map(beer => {
                return (beer.styleRankingPosition = pos++);
            });
        } else {
            let pos = 1;
            action.ranking.map(beer => {
                if (beer.new_release) {
                    hasNewReleases = true;
                }
                return (beer.styleRankingPosition = pos++);
            });
        }
        // Filter by search terms
        let fRanking = action.ranking;
        if (state.searchTerm) {
            fRanking = rankingIndex.search(state.searchTerm);
            fRanking = fRanking.sort((a, b) => {
                if (a.position < b.position) return -1;
                if (a.position > b.position) return 1;
                return 0;
            });
        }
        return {
            ...state,
            beerRanking: action.ranking,
            filteredBeerRanking: fRanking,
            styleFilteredBeerRanking: sfRanking,
            hasNewReleases,
            rankingIndex: rankingIndex
        };
    }
    return {
        ...state,
        beerRanking: [],
        startTime: null,
        hasNewReleases: false
    };
};

const exitFestival = (state, action) => {
    // TODO: off firebase
    return {
        ...state,
        currentFestival: null,
        currentFestivalConfig: null,
        startTime: null,
        beerRanking: null,
        filteredBeerRanking: null,
        styleFilteredBeerRanking: null,
        rankingIndex: null,
        searchTerm: "",
        breweryRanking: null,
        stats: null,
        users: null,
        styles: null,
        selectedStyle: "",
        hasNewReleases: false,
        breweryLineup: null,
        beerList: null
    };
};

const getStyleList = (styles, hasNewReleases) => {
    if (styles) {
        let sortedStyles = Object.values(styles);
        sortedStyles.sort((a, b) => {
            if (a.style_name === "Outros" || a.style_name === "Others")
                return 1;
            if (b.style_name === "Outros" || b.style_name === "Others")
                return -1;
            if (a.num_checkins < b.num_checkins) return 1;
            if (a.num_checkins > b.num_checkins) return -1;
            return 0;
        });
        if (hasNewReleases) {
            sortedStyles.unshift({
                style_name: i18n.t("new_releases")
            });
        }
        sortedStyles.unshift({
            style_name: i18n.t("all_beers")
        });
        return sortedStyles;
    } else {
        return [
            {
                style_name: i18n.t("all_beers")
            }
        ];
    }
};

const setStyles = (state, action) => {
    return {
        ...state,
        topStyles: action.styles,
        styles: getStyleList(action.styles, state.hasNewReleases)
    };
};

const setBeerList = (state, action) => {
    return {
        ...state,
        beerList: action.beerList
    };
};

const setBreweryLineup = (state, action) => {
    return {
        ...state,
        breweryLineup: action.breweryLineup
    };
};

const setSelectedStyle = (state, action) => {
    if (action.selectedStyle === i18n.t("new_releases")) {
        let sfRanking = state.beerRanking.filter(beer => beer.new_release);
        let pos = 1;
        sfRanking.map(beer => {
            return (beer.styleRankingPosition = pos++);
        });
        return {
            ...state,
            selectedStyle: action.selectedStyle,
            styleFilteredBeerRanking: sfRanking
        };
    } else if (
        action.selectedStyle &&
        state.styleGroups &&
        Object.keys(state.styleGroups).includes(action.selectedStyle)
    ) {
        let sfRanking = state.beerRanking.filter(beer =>
            state.styleGroups[action.selectedStyle].includes(beer.style)
        );
        let pos = 1;
        sfRanking.map(beer => {
            return (beer.styleRankingPosition = pos++);
        });
        return {
            ...state,
            selectedStyle: action.selectedStyle,
            styleFilteredBeerRanking: sfRanking
        };
    } else {
        return {
            ...state,
            selectedStyle: "",
            styleFilteredBeerRanking: state.beerRanking
        };
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_FESTIVAL:
            return {
                ...state,
                currentFestival: action.festivalInfo,
                startTime: new Date(action.festivalInfo.start_time)
            };
        case actionTypes.EXIT_FESTIVAL:
            return exitFestival(state, action);
        case actionTypes.SET_BEER_RANKING:
            return setBeerRanking(state, action);
        case actionTypes.SET_BEER_LIST:
            return setBeerList(state, action);
        case actionTypes.SET_BREWERY_LINEUP:
            return setBreweryLineup(state, action);
        case actionTypes.FILTER_BEER_RANKING:
            if (action.terms && action.terms.trim() && state.rankingIndex) {
                let sortedFilteredBeerRanking = state.rankingIndex.search(
                    action.terms
                );
                sortedFilteredBeerRanking = sortedFilteredBeerRanking.sort(
                    (a, b) => {
                        if (a.position < b.position) return -1;
                        if (a.position > b.position) return 1;
                        return 0;
                    }
                );
                return {
                    ...state,
                    searchTerm: action.terms,
                    filteredBeerRanking: sortedFilteredBeerRanking
                };
            } else {
                return {
                    ...state,
                    searchTerm: action.terms,
                    filteredBeerRanking: state.beerRanking
                };
            }
        case actionTypes.SET_BREWERY_RANKING:
            if (action.ranking) {
                return {
                    ...state,
                    breweryRanking: action.ranking
                };
            }
            return {
                breweryRanking: []
            };
        case actionTypes.SET_STATS:
            return {
                ...state,
                stats: action.stats
            };
        case actionTypes.SET_TOP_USERS:
            if (action.users) {
                return {
                    ...state,
                    users: action.users
                };
            } else {
                return {
                    ...state,
                    users: []
                };
            }
        case actionTypes.SET_STYLES:
            return setStyles(state, action);
        case actionTypes.SET_SELECTED_STYLE:
            return setSelectedStyle(state, action);
        case actionTypes.SET_STYLE_GROUPS:
            return {
                ...state,
                styleGroups: action.styleGroups
            };
        case actionTypes.SET_FESTIVAL_CONFIG:
            return setFestivalConfig(state, action);
        default:
            return state;
    }
};

export default reducer;
