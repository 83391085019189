import React from "react";
import classes from "./BottomToolbar.css";
import ToolbarIcon from "./ToolbarIcon/ToolbarIcon";
import { withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";

const bottomToolbar = props => {
    const { t, config } = props;

    return (
        <footer className={classes.Footer}>
            <div className={classes.BottomToolbar}>
                <ToolbarIcon
                    link={"/festival/" + props.match.params.id + "/info"}
                    label={t("info")}
                    icon="info-circle"
                />
                {config && config.enable_beer_list && (
                    <ToolbarIcon
                        link={
                            "/festival/" + props.match.params.id + "/beer-list"
                        }
                        label={t("beer_list")}
                        icon="beer"
                    />
                )}
                <ToolbarIcon
                    link={"/festival/" + props.match.params.id + "/discover"}
                    label={t("discover")}
                    icon="compass"
                />
                <ToolbarIcon
                    link={"/festival/" + props.match.params.id + "/ranking"}
                    label={t("ranking")}
                    icon="trophy"
                />
                <ToolbarIcon
                    link={"/festival/" + props.match.params.id + "/stats"}
                    label={t("stats")}
                    icon="chart-line"
                />
                <ToolbarIcon
                    link={"/festival/" + props.match.params.id + "/attendees"}
                    label={t("attendees")}
                    icon="users"
                />
            </div>
        </footer>
    );
};

const mapStateToProps = state => {
    return {
        config: state.festivalStage.currentFestivalConfig
    };
};

export default withI18n()(
    withRouter(
        connect(
            mapStateToProps,
            null
        )(bottomToolbar)
    )
);
