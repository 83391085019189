import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import "./index.css?raw";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import reducer from "./store/reducers/index";
import Auxi from "./hoc/Auxi";
import i18n from "./i18n/i18n";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

ReactGA.initialize("UA-109838957-3");

/**
 * @return {null}
 */
function Analytics(props) {
    ReactGA.set({ page: props.location.pathname + props.location.search });
    ReactGA.pageview(props.location.pathname + props.location.search);
    return null;
}

const app = (
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter basename="/">
                <Auxi>
                    <Route path="/" component={Analytics} />
                    <App />
                </Auxi>
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
registerServiceWorker();
