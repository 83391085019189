import React from "react";

import classes from "./Message.css";

const message = props => (
    <div className={classes.MessageContainer}>
        <div className={classes.Message}>{props.children}</div>
    </div>
);

export default message;
