import React, { Component } from "react";
import classes from "./Modal.css";
import Auxi from "../../../hoc/Auxi";
import Backdrop from "../Backdrop/Backdrop";

class Modal extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.show !== this.props.show ||
            nextProps.children !== this.props.children
        );
    }

    render() {
        return (
            <Auxi>
                <Backdrop
                    clicked={this.props.modalClosed}
                    show={this.props.show}
                />
                <div
                    className={classes.Modal}
                    style={{
                        zIndex: this.props.show ? "500" : "-1",
                        opacity: this.props.show ? "1" : "0"
                    }}
                >
                    {this.props.children}
                </div>
            </Auxi>
        );
    }
}

export default Modal;
