import React from "react";
import FontAwesome from "react-fontawesome";
import { NavLink } from "react-router-dom";

import classes from "./SideToolbarItem.css";

const sideToolbarItem = props => (
    <NavLink
        className={classes.Link}
        activeClassName={classes.Active}
        to={props.link}
    >
        <div className={classes.ToolbarItem}>
            <FontAwesome
                name={props.icon}
                className={classes.ToolbarItemIcon}
            />{" "}
            {props.label}
        </div>
    </NavLink>
);

export default sideToolbarItem;
