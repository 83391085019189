import firebase from "../../firebase-bs";

import * as actionTypes from "./actionTypes";

export const setFestivalList = festivalList => {
    return {
        type: actionTypes.SET_FESTIVAL_LIST,
        festivalList: festivalList
    };
};

export const loadFestivalList = () => {
    return dispatch => {
        const currentFestivalsRef = firebase.ref("/festival-list/current");
        currentFestivalsRef.once("value", snapshot => {
            let list = snapshot.val();
            if (!list) list = [];
            dispatch(setFestivalList(list));
        });
    };
};

export const setOldFestivalList = oldFestivalList => {
    return {
        type: actionTypes.SET_OLD_FESTIVAL_LIST,
        oldFestivalList: oldFestivalList
    };
};

export const loadOldFestivalList = () => {
    return dispatch => {
        const oldFestivalsRef = firebase.ref("/festival-list/old");
        oldFestivalsRef.once("value", snapshot => {
            let list = snapshot.val();
            if (!list) list = [];
            dispatch(setOldFestivalList(list));
        });
    };
};
