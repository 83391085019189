import React from "react";
import { Line } from "react-chartjs-2";
import i18n from "i18next";

const histogramChart = props => {
    const getArray = data => {
        const lastKey = new Date()
            .toISOString()
            .slice(0, 13)
            .replace("T", " ");
        const ret = {
            labels: [],
            values: []
        };
        const keys = Object.keys(data);
        keys.sort();
        for (let key of keys) {
            const label = new Date(
                Date.parse(key + ":00+0000")
            ).toLocaleTimeString(i18n.language, {
                day: "numeric",
                month: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            });
            ret.labels.push(label);
            ret.values.push(data[key]);
            if (key.startsWith(lastKey)) break;
        }
        return ret;
    };

    const data = getArray(props.histogram);

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: "Check-ins",
                fill: true,
                borderWidth: 2,
                tension: 0.05,
                borderColor: "rgba(201,75,12,1)",
                backgroundColor: "rgba(201,75,12,1)",
                data: data.values
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    ticks: {
                        autoSkip: true
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false
                    }
                }
            ]
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 5
            }
        },
        layout: {
            padding: {
                bottom: 10
            }
        }
    };

    return <Line data={chartData} options={chartOptions} height={60} />;
};

export default histogramChart;
