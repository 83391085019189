import React, { Component } from "react";
import RankingItem from "../../../../components/RankingItem/RankingItem";
import classes from "../Ranking.css";
import { connect } from "react-redux";
import Message from "../../../../components/UI/Message/Message";
import MetaTags from "react-meta-tags";
import RankingPlaceholder from "../../RankingPlaceholder/RankingPlaceholder";
import { withI18n } from "react-i18next";

const BREWERY_DEFAULT_IMAGE =
    "https://untappd.akamaized.net/site/assets/images/temp/badge-brewery-default.png";

class BreweryRanking extends Component {
    state = {
        numItems: 20
    };

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll = () => {
        if (
            window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 500 &&
            this.state.numItems &&
            this.props.ranking
        ) {
            this.setState(prevState => {
                let newValue = prevState.numItems + 20;
                if (newValue > this.props.ranking.length) {
                    newValue = this.props.ranking.length;
                }
                return {
                    numItems: newValue
                };
            });
        }
    };

    render() {
        const { t } = this.props;
        let ranking = null;
        if (!this.props.ranking) {
            ranking = <RankingPlaceholder loading />;
        } else if (this.props.ranking.length === 0) {
            ranking = <Message>{t("empty_brewery_ranking")}</Message>;
        } else {
            ranking = this.props.ranking
                .slice(0, this.state.numItems)
                .map(brewery => {
                    let beerCount = "?";
                    if (brewery.beers) {
                        beerCount = Object.keys(brewery.beers).length;
                    }
                    let brewery_img = brewery.img;
                    if (!brewery_img) {
                        brewery_img = BREWERY_DEFAULT_IMAGE;
                    }
                    return (
                        <RankingItem
                            position={brewery.position}
                            img={brewery_img}
                            default_img={BREWERY_DEFAULT_IMAGE}
                            name={brewery.name}
                            key={brewery.brewery_id}
                            id={brewery.brewery_id}
                            subtitle={
                                t("weighted_avg") +
                                " " +
                                brewery.weighted_rating.toFixed(2)
                            }
                            details={
                                brewery.num_checkins +
                                (brewery.num_checkins === 1
                                    ? " check-in / "
                                    : " check-ins / ") +
                                t("beer_count", { count: beerCount })
                            }
                            href={
                                "https://untappd.com/b/go-to/" +
                                brewery.brewery_id
                            }
                        />
                    );
                });
        }
        return (
            <div className={classes.Ranking}>
                <MetaTags>
                    <title>
                        {this.props.festival.name} |{" "}
                        {t("brewery_ranking_title")} | Brewer Stage
                    </title>
                    <meta
                        name="description"
                        content={t("brewery_ranking_desc", {
                            festival: this.props.festival.name
                        })}
                    />
                </MetaTags>
                {ranking}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        festival: state.festivalStage.currentFestival
    };
};

export default withI18n()(connect(mapStateToProps)(BreweryRanking));
