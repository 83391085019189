/**
 * English i18n strings.
 */

export default {
    translations: {
        festivals: "Festivals",
        exit_site: "Back to website",
        previous_festivals: "Previous festivals",
        info: "Info",
        discover: "Discover",
        ranking: "Ranking",
        stats: "Stats",
        attendees: "Attendees",
        discover_ad:
            "Discover {{festival}}. News, new beers notifications, highlights and more. Feature only available on Brewer Stage App. Download now!",
        countdown_days: "DAYS",
        countdown_hours: "HOURS",
        countdown_minutes: "MIN.",
        countdown_seconds: "SEC.",
        loading: "Loading...",
        search_placeholder: "Search...",
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may_full: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        tba: "TBA",
        festival_list_title: "Beer Events and Festivals {{year}}",
        festival_list_desc:
            "Follow best beer festivals of {{year}}. Beers and breweries top list, news, info and highlights.",
        info_title: "Pratical Information",
        info_desc:
            "General information about {{festival}}. Tickets, place, breweries and beers line-up.",
        global_position: "Global position",
        weighted_avg_score: "Weighted score average",
        simple_avg: "Simple average",
        learn: "Learn",
        checkins: "Check-ins",
        rating_scores: "Rating scores",
        biggest_fans: "Biggest fans",
        empty_beer_search: "No beers to show.",
        empty_beer_ranking:
            "No beer reached the minimum number of check-ins to appear in this list.",
        beer_ranking_title: "Top Beers",
        beer_ranking_desc:
            "Top {{festival}} beer, according to public rating scores.",
        sharing_img: "Share image",
        empty_brewery_ranking:
            "No brewery reached the minimum number of check-ins to appear in this list.",
        weighted_avg: "Weighted average",
        beer_count: "{{count}} beer",
        beer_count_plural: "{{count}} beers",
        brewery_ranking_title: "Top Breweries",
        brewery_ranking_desc:
            "Top {{festival}} breweries, according to public rating scores.",
        share: "Share",
        beer_share_message:
            "The best {{style}} of the festival {{festival_name}}, according to all Untappd check-ins. Full ranking: {{url}}",
        beer_share_title: "Best {{style}} - {{festival_name}}",
        beers: "beers",
        beers_tab: "Beers",
        breweries_tab: "Breweries",
        checkins_total: "Check-ins total",
        checkins_brewery: "Check-ins by brewery",
        festival_average: "Festival average rating score",
        users: "Users",
        last_update: "Last update",
        stats_title: "Statistics",
        stats_desc:
            "{{festival}} statistics. Total number of check-ins, number of check-ins by brewery and beer style.",
        late_checkins_message:
            "Festival has ended! Waiting for late check-ins.",
        no_user_ranking: "No one in this ranking.",
        rated_beer_count: "{{count}} rated beer",
        rated_beer_count_plural: "{{count}} different beers rated",
        top_users_title: "Attendees",
        top_users_desc: "Top {{festival}} attendees.",
        countdown_alert:
            "Rankings and statistics will only be available after the begining of the festival.",
        old_festivals_title: "Previous Beer Festivals",
        old_festivals_desc:
            "Top beers and breweries from previous beer events and festivals.",
        all_beers: "All beers",
        // Countries
        country_AD: "Andorra",
        country_AE: "United Arab Emirates",
        country_AF: "Afghanistan",
        country_AG: "Antigua and Barbuda",
        country_AI: "Anguilla",
        country_AL: "Albania",
        country_AM: "Armenia",
        country_AN: "Netherlands Antilles",
        country_AO: "Angola",
        country_AQ: "Antarctica",
        country_AR: "Argentina",
        country_AS: "American Samoa",
        country_AT: "Austria",
        country_AU: "Australia",
        country_AW: "Aruba",
        country_AX: "Aland Islands",
        country_AZ: "Azerbaijan",
        country_BA: "Bosnia and Herzegovina",
        country_BB: "Barbados",
        country_BD: "Bangladesh",
        country_BE: "Belgium",
        country_BF: "Burkina Faso",
        country_BG: "Bulgaria",
        country_BH: "Bahrain",
        country_BI: "Burundi",
        country_BJ: "Benin",
        country_BL: "Saint-Barthélemy",
        country_BM: "Bermuda",
        country_BN: "Brunei Darussalam",
        country_BO: "Bolivia",
        country_BR: "Brazil",
        country_BS: "Bahamas",
        country_BT: "Bhutan",
        country_BV: "Bouvet Island",
        country_BW: "Botswana",
        country_BY: "Belarus",
        country_BZ: "Belize",
        country_CA: "Canada",
        country_CC: "Cocos (Keeling) Islands",
        country_CD: "Congo (Kinshasa)",
        country_CF: "Central African Republic",
        country_CG: "Congo (Brazzaville)",
        country_CH: "Switzerland",
        country_CI: "Côte d'Ivoire",
        country_CK: "Cook Islands",
        country_CL: "Chile",
        country_CM: "Cameroon",
        country_CN: "China",
        country_CO: "Colombia",
        country_CR: "Costa Rica",
        country_CU: "Cuba",
        country_CV: "Cape Verde",
        country_CX: "Christmas Island",
        country_CY: "Cyprus",
        country_CZ: "Czech Republic",
        country_DE: "Germany",
        country_DJ: "Djibouti",
        country_DK: "Denmark",
        country_DM: "Dominica",
        country_DO: "Dominican Republic",
        country_DZ: "Algeria",
        country_EC: "Ecuador",
        country_EE: "Estonia",
        country_EG: "Egypt",
        country_EH: "Western Sahara",
        country_ER: "Eritrea",
        country_ES: "Spain",
        country_ET: "Ethiopia",
        country_FI: "Finland",
        country_FJ: "Fiji",
        country_FK: "Falkland Islands (Malvinas)",
        country_FM: "Micronesia, Federated States of",
        country_FO: "Faroe Islands",
        country_FR: "France",
        country_GA: "Gabon",
        country_GB: "United Kingdom",
        country_GD: "Grenada",
        country_GE: "Georgia",
        country_GF: "French Guiana",
        country_GG: "Guernsey",
        country_GH: "Ghana",
        country_GI: "Gibraltar",
        country_GL: "Greenland",
        country_GM: "Gambia",
        country_GN: "Guinea",
        country_GP: "Guadeloupe",
        country_GQ: "Equatorial Guinea",
        country_GR: "Greece",
        country_GS: "South Georgia and the South Sandwich Islands",
        country_GT: "Guatemala",
        country_GU: "Guam",
        country_GW: "Guinea-Bissau",
        country_GY: "Guyana",
        country_HK: "Hong Kong",
        country_HM: "Heard and Mcdonald Islands",
        country_HN: "Honduras",
        country_HR: "Croatia",
        country_HT: "Haiti",
        country_HU: "Hungary",
        country_ID: "Indonesia",
        country_IE: "Ireland",
        country_IL: "Israel",
        country_IM: "Isle of Man",
        country_IN: "India",
        country_IO: "British Indian Ocean Territory",
        country_IQ: "Iraq",
        country_IR: "Iran, Islamic Republic of",
        country_IS: "Iceland",
        country_IT: "Italy",
        country_JE: "Jersey",
        country_JM: "Jamaica",
        country_JO: "Jordan",
        country_JP: "Japan",
        country_KE: "Kenya",
        country_KG: "Kyrgyzstan",
        country_KH: "Cambodia",
        country_KI: "Kiribati",
        country_KM: "Comoros",
        country_KN: "Saint Kitts and Nevis",
        country_KP: "Korea (North)",
        country_KR: "Korea (South)",
        country_KW: "Kuwait",
        country_KY: "Cayman Islands",
        country_KZ: "Kazakhstan",
        country_LA: "Lao PDR",
        country_LB: "Lebanon",
        country_LC: "Saint Lucia",
        country_LI: "Liechtenstein",
        country_LK: "Sri Lanka",
        country_LR: "Liberia",
        country_LS: "Lesotho",
        country_LT: "Lithuania",
        country_LU: "Luxembourg",
        country_LV: "Latvia",
        country_LY: "Libya",
        country_MA: "Morocco",
        country_MC: "Monaco",
        country_MD: "Moldova",
        country_ME: "Montenegro",
        country_MF: "Saint-Martin (French part)",
        country_MG: "Madagascar",
        country_MH: "Marshall Islands",
        country_MK: "Macedonia, Republic of",
        country_ML: "Mali",
        country_MM: "Myanmar",
        country_MN: "Mongolia",
        country_MO: "Macao, SAR China",
        country_MP: "Northern Mariana Islands",
        country_MQ: "Martinique",
        country_MR: "Mauritania",
        country_MS: "Montserrat",
        country_MT: "Malta",
        country_MU: "Mauritius",
        country_MV: "Maldives",
        country_MW: "Malawi",
        country_MX: "Mexico",
        country_MY: "Malaysia",
        country_MZ: "Mozambique",
        country_NA: "Namibia",
        country_NC: "New Caledonia",
        country_NE: "Niger",
        country_NF: "Norfolk Island",
        country_NG: "Nigeria",
        country_NI: "Nicaragua",
        country_NL: "Netherlands",
        country_NO: "Norway",
        country_NP: "Nepal",
        country_NR: "Nauru",
        country_NU: "Niue",
        country_NZ: "New Zealand",
        country_OM: "Oman",
        country_PA: "Panama",
        country_PE: "Peru",
        country_PF: "French Polynesia",
        country_PG: "Papua New Guinea",
        country_PH: "Philippines",
        country_PK: "Pakistan",
        country_PL: "Poland",
        country_PM: "Saint Pierre and Miquelon",
        country_PN: "Pitcairn",
        country_PR: "Puerto Rico",
        country_PS: "Palestinian Territory",
        country_PT: "Portugal",
        country_PW: "Palau",
        country_PY: "Paraguay",
        country_QA: "Qatar",
        country_RE: "Réunion",
        country_RO: "Romania",
        country_RS: "Serbia",
        country_RU: "Russian Federation",
        country_RW: "Rwanda",
        country_SA: "Saudi Arabia",
        country_SB: "Solomon Islands",
        country_SC: "Seychelles",
        country_SD: "Sudan",
        country_SE: "Sweden",
        country_SG: "Singapore",
        country_SH: "Saint Helena",
        country_SI: "Slovenia",
        country_SJ: "Svalbard and Jan Mayen Islands",
        country_SK: "Slovakia",
        country_SL: "Sierra Leone",
        country_SM: "San Marino",
        country_SN: "Senegal",
        country_SO: "Somalia",
        country_SR: "Suriname",
        country_ST: "Sao Tome and Principe",
        country_SV: "El Salvador",
        country_SY: "Syrian Arab Republic (Syria)",
        country_SZ: "Swaziland",
        country_TC: "Turks and Caicos Islands",
        country_TD: "Chad",
        country_TF: "French Southern Territories",
        country_TG: "Togo",
        country_TH: "Thailand",
        country_TJ: "Tajikistan",
        country_TK: "Tokelau",
        country_TL: "Timor-Leste",
        country_TM: "Turkmenistan",
        country_TN: "Tunisia",
        country_TO: "Tonga",
        country_TR: "Turkey",
        country_TT: "Trinidad and Tobago",
        country_TV: "Tuvalu",
        country_TW: "Taiwan, Republic of China",
        country_TZ: "Tanzania, United Republic of",
        country_UA: "Ukraine",
        country_UG: "Uganda",
        country_UM: "US Minor Outlying Islands",
        country_US: "United States of America",
        country_UY: "Uruguay",
        country_UZ: "Uzbekistan",
        country_VA: "Holy See (Vatican City State)",
        country_VC: "Saint Vincent and Grenadines",
        country_VE: "Venezuela",
        country_VG: "British Virgin Islands",
        country_VI: "Virgin Islands, US",
        country_VN: "Viet Nam",
        country_VU: "Vanuatu",
        country_WF: "Wallis and Futuna Islands",
        country_WS: "Samoa",
        country_YE: "Yemen",
        country_YT: "Mayotte",
        country_ZA: "South Africa",
        country_ZM: "Zambia",
        country_ZW: "Zimbabwe",
        beer_list: "Beer list"
    }
};
