import React, { Component } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Auxi from "../../../hoc/Auxi";
import Tabs from "../../../components/Navigation/Tabs/Tabs";
import Tab from "../../../components/Navigation/Tabs/Tab/Tab";
import BeerRanking from "./BeerRanking/BeerRanking";
import BreweryRanking from "./BreweryRanking/BreweryRanking";
import * as actions from "../../../store/actions/index";
import { withI18n } from "react-i18next";

// import FloatingActionButton from "./FloatingActionButton/FloatingActionButton";

class Ranking extends Component {
    componentDidMount() {
        this.props.loadBeerRanking(this.props.festivalId);
        this.props.loadBreweryRanking(this.props.festivalId);
        this.props.loadStyles(this.props.festivalId);
    }

    componentWillUnmount() {
        actions.unloadRanking(this.props.festivalId);
        actions.unloadStyles(this.props.festivalId);
    }

    render() {
        const { t } = this.props;
        if (this.props.config && this.props.config.disable_brewery_ranking) {
            return (
                <div style={{ paddingTop: 8 }}>
                    <BeerRanking />
                </div>
            );
        }
        return (
            <Auxi>
                <Tabs>
                    <Tab
                        link={
                            "/festival/" +
                            this.props.festivalId +
                            "/ranking/beers"
                        }
                    >
                        {t("beers_tab")}
                    </Tab>
                    <Tab
                        link={
                            "/festival/" +
                            this.props.festivalId +
                            "/ranking/breweries"
                        }
                    >
                        {t("breweries_tab")}
                    </Tab>
                </Tabs>
                <Switch>
                    <Route
                        path="/festival/:id/ranking/beers"
                        exact
                        component={BeerRanking}
                    />
                    <Route
                        path="/festival/:id/ranking/breweries"
                        exact
                        render={() => (
                            <BreweryRanking
                                ranking={this.props.breweryRanking}
                            />
                        )}
                    />
                    <Redirect
                        from="/festival/:id/ranking"
                        exact
                        to={
                            "/festival/" +
                            this.props.festivalId +
                            "/ranking/beers"
                        }
                    />
                </Switch>
            </Auxi>
        );
    }
}

const mapStateToProps = state => {
    return {
        breweryRanking: state.festivalStage.breweryRanking,
        config: state.festivalStage.currentFestivalConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadBeerRanking: id => dispatch(actions.loadBeerRanking(id)),
        loadBreweryRanking: id => dispatch(actions.loadBreweryRanking(id)),
        loadStyles: id => dispatch(actions.loadStyles(id))
    };
};

export default withI18n()(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(Ranking)
    )
);
