import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./NavigationItem.css";
import * as actions from "../../../../store/actions/index";

class NavigationItem extends Component {
    click = event => {
        this.props.closeSideDrawer();
    };

    render() {
        let link = (
            <NavLink
                onClick={this.click}
                to={this.props.link}
                exact={this.props.exact}
            >
                {this.props.children}
            </NavLink>
        );

        if (this.props.link.startsWith("http")) {
            link = <a href={this.props.link}>{this.props.children}</a>;
        }

        return <li className={classes.NavigationItem}>{link}</li>;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSideDrawer: () => dispatch(actions.closeSideDrawer())
    };
};

export default connect(null, mapDispatchToProps)(withRouter(NavigationItem));
