import React from 'react';
import bsLogo from '../../assets/images/pbu_160_white.png';
import classes from './PoweredByUntappd.css';

const pbu = (props) => (
    <div className={classes.PoweredByUntappd} style={{height: props.height}}>
        <img src={bsLogo} alt="Powered By Untappd" />
    </div>
);

export default pbu;