import * as actionTypes from "./actionTypes";

export const openSideDrawer = () => {
    return {
        type: actionTypes.OPEN_SIDE_DRAWER
    };
};

export const closeSideDrawer = () => {
    return {
        type: actionTypes.CLOSE_SIDE_DRAWER
    };
};

export const setErrorState = error => {
    return {
        type: actionTypes.SET_ERROR_STATE,
        error: error
    };
};

export const clearErrorState = () => {
    return {
        type: actionTypes.CLEAR_ERROR_STATE
    };
};
