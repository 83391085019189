const ACCENT_MAP = {
  á: "a",
  ã: "a",
  à: "a",
  ä: "a",
  ê: "e",
  é: "e",
  ó: "o",
  ô: "o",
  ú: "u",
  ü: "u",
  ñ: "n",
  í: "i",
  ç: "c"
};

class AccentFoldingSanitizer {
  sanitize(text) {
    if (!text) return "";
    let sanitized = "";

    text = text.toLowerCase().trim();

    for (let i = 0; i < text.length; i++) {
      const char = text[i];

      sanitized += ACCENT_MAP.hasOwnProperty(char) ? ACCENT_MAP[char] : char;
    }

    return sanitized;
  }
}

export default AccentFoldingSanitizer;
