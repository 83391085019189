import React, { Component } from "react";
import classes from "./BreweryLineup.css";
import Loader from "../../../../components/UI/Loader/Loader";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";

class BreweryLineup extends Component {
    componentDidMount() {
        this.props.loadBreweryLineup(this.props.festivalId);
    }

    componentWillUnmount() {
        actions.unloadBreweryLineup();
    }

    render() {
        const { breweryLineup } = this.props;
        if (!breweryLineup) return <Loader />;
        return (
            <div className={classes.BreweryLineup}>
                {breweryLineup.map(brewery => {
                    return (
                        <div key={brewery.id} className={classes.Brewery}>
                            <img
                                className={classes.BreweryImage}
                                alt={brewery.name}
                                src={brewery.img}
                            />
                            <div className={classes.BreweryName}>
                                {brewery.name}
                            </div>
                            <div className={classes.BreweryLocation}>
                                {brewery.location}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        breweryLineup: state.festivalStage.breweryLineup
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadBreweryLineup: festivalId =>
            dispatch(actions.loadBreweryLineup(festivalId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreweryLineup);
