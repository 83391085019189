import React, { Component } from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import SearchBox from "../../../../components/UI/SearchBox/SearchBox";
import MetaTags from "react-meta-tags";
import RankingItem from "../../../../components/RankingItem/RankingItem";
import classes from "../Ranking.css";
import Modal from "../../../../components/UI/Modal/Modal";
import BeerInfo from "./BeerInfo/BeerInfo";
import * as actions from "../../../../store/actions/index";
import Auxi from "../../../../hoc/Auxi";
import Message from "../../../../components/UI/Message/Message";
import RankingImageShare from "../RankingImageShare/RankingImageShare";
import RankingPlaceholder from "../../RankingPlaceholder/RankingPlaceholder";
import { withI18n } from "react-i18next";

const DEFAULT_IMAGE =
    "https://untappd.akamaized.net/site/assets/images/temp/badge-beer-default.png";

class BeerRanking extends Component {
    state = {
        beerSelected: null,
        filtered: false,
        numItems: 20,
        showShareImage: false
    };

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll = () => {
        this.setState({
            menuVisible: false
        });
        if (
            window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 500 &&
            this.state.numItems &&
            this.props.ranking
        ) {
            this.setState(prevState => {
                let newValue = prevState.numItems + 20;
                if (newValue > this.props.ranking.length) {
                    newValue = this.props.ranking.length;
                }
                return {
                    numItems: newValue
                };
            });
        }
    };

    beerClickHandler = beer => {
        this.setState({ beerSelected: beer });
    };

    modalClosedHandler = beer => {
        this.setState({ beerSelected: null, showShareImage: false });
    };

    filterHandler = event => {
        this.setState({ filtered: event.target.value.trim() !== "" });
        this.props.setSelectedStyle("");
        return this.props.filterBeerRanking(event.target.value);
    };

    styleChangeHandler = event => {
        this.setState({
            numItems: 20
        });
        this.props.setSelectedStyle(event.target.value);
    };

    renderStyleFilter() {
        if (!this.props.styles || this.props.styles.length === 0) {
            return null;
        }
        return (
            <div className={classes.FilterContainer}>
                <select
                    onChange={this.styleChangeHandler}
                    value={this.props.selectedStyle}
                    className={classes.StylePicker}
                >
                    {this.props.styles.map(style => {
                        return (
                            <option
                                key={style.style_name}
                                value={style.style_name}
                            >
                                {style.style_name}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    toggleMenu = e => {
        if (this.contextTrigger) {
            this.contextTrigger.handleContextClick(e);
        }
    };

    openShareImageHandler = () => {
        if (this.props.festival) {
            this.setState({
                showShareImage: true
            });
        }
    };

    render() {
        const { t } = this.props;
        let ranking = null;

        if (!this.props.ranking) {
            ranking = <RankingPlaceholder loading />;
        } else if (this.props.ranking.length === 0) {
            if (this.state.filtered) {
                ranking = <Message>{t("empty_beer_search")}</Message>;
            } else {
                ranking = <Message>{t("empty_beer_ranking")}</Message>;
            }
        } else {
            let rankingList = this.props.ranking;
            if (this.props.searchTerm) {
                rankingList = this.props.filteredBeerRanking;
            }
            ranking = rankingList.slice(0, this.state.numItems).map(beer => {
                let beer_img = beer.img;
                if (!beer_img) {
                    beer_img = DEFAULT_IMAGE;
                }
                return (
                    <RankingItem
                        position={
                            this.props.selectedStyle
                                ? beer.styleRankingPosition
                                : beer.position
                        }
                        img={beer_img}
                        default_img={DEFAULT_IMAGE}
                        name={beer.beer}
                        key={beer.beer_id}
                        id={beer.beer_id}
                        subtitle={beer.brewery}
                        click={() => this.beerClickHandler(beer)}
                        details={
                            beer.weighted_rating.toFixed(2) +
                            " (" +
                            beer.num_checkins +
                            (beer.num_checkins === 1
                                ? " check-in) / "
                                : " check-ins) / ") +
                            beer.style
                        }
                        href={"https://untappd.com/b/go-to/" + beer.beer_id}
                    />
                );
            });
        }
        return (
            <Auxi>
                <MetaTags>
                    <title>
                        {this.props.festival.name} | {t("beer_ranking_title")} |
                        Brewer Stage
                    </title>
                    <meta
                        name="description"
                        content={t("beer_ranking_desc", {
                            festival: this.props.festival.name
                        })}
                    />
                </MetaTags>
                <ContextMenu id="share-menu">
                    <MenuItem
                        data={{ foo: "bar" }}
                        onClick={this.openShareImageHandler}
                        className={classes.ContextMenuItem}
                        disabled={
                            !this.props.ranking ||
                            this.props.ranking.length === 0 ||
                            this.props.selectedStyle === "Other" ||
                            this.props.selectedStyle === "Outras" ||
                            this.props.selectedStyle === "Outros"
                        }
                    >
                        {t("sharing_img")}
                    </MenuItem>
                </ContextMenu>
                <Modal
                    show={this.state.beerSelected}
                    modalClosed={this.modalClosedHandler}
                >
                    <BeerInfo beer={this.state.beerSelected} />
                </Modal>
                <Modal
                    show={this.state.showShareImage}
                    modalClosed={this.modalClosedHandler}
                >
                    <RankingImageShare
                        festival={this.props.festival}
                        selectedStyle={this.props.selectedStyle}
                    />
                </Modal>
                <div className={classes.FilterBar}>
                    <SearchBox
                        value={this.props.searchTerm}
                        filter={this.filterHandler}
                        placeholder={t("search_placeholder")}
                    />
                    {this.props.searchTerm ? null : (
                        <Auxi>
                            {this.renderStyleFilter()}
                            <ContextMenuTrigger
                                ref={c => (this.contextTrigger = c)}
                                id="share-menu"
                            >
                                <div
                                    className={classes.ShareIcon}
                                    onClick={this.toggleMenu}
                                >
                                    <FontAwesome name="ellipsis-v" />
                                </div>
                            </ContextMenuTrigger>
                        </Auxi>
                    )}
                </div>
                <div className={classes.Ranking}>{ranking}</div>
            </Auxi>
        );
    }
}

const mapStateToProps = state => {
    return {
        ranking: state.festivalStage.styleFilteredBeerRanking,
        filteredBeerRanking: state.festivalStage.filteredBeerRanking,
        rankingIndex: state.festivalStage.rankingIndex,
        searchTerm: state.festivalStage.searchTerm,
        styles: state.festivalStage.styles,
        selectedStyle: state.festivalStage.selectedStyle,
        festival: state.festivalStage.currentFestival
    };
};

const mapDispatchToProps = dispatch => {
    return {
        filterBeerRanking: terms => dispatch(actions.filterBeerRanking(terms)),
        setSelectedStyle: selectedStyle =>
            dispatch(actions.setSelectedStyle(selectedStyle))
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BeerRanking)
);
