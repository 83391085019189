import React from "react";
import MetaTags from "react-meta-tags";

import classes from "./OnlyOnApp.css";
import appleBadge from "../../assets/images/apple-store-badge.svg";
import androidBadge from "../../assets/images/google-play-badge.png";
import { withI18n } from "react-i18next";

const OnlyOnApp = props => {
    const { t } = props;
    return (
        <div className={classes.Container}>
            <MetaTags>
                <title>
                    {props.festival.name} | {t("discover")} | Brewer Stage
                </title>
                <meta name="description" content={props.message} />
            </MetaTags>
            <div className={classes.Message}>{props.message}</div>
            <div className={classes.Badges}>
                <a href="https://itunes.apple.com/us/app/brewer-stage/id1401075166?l=pt&ls=1&mt=8">
                    <img
                        className={classes.Badge}
                        src={appleBadge}
                        alt="Apple Store"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.brewerstagemobile">
                    <img
                        className={classes.Badge}
                        src={androidBadge}
                        alt="Google Play"
                    />
                </a>
            </div>
        </div>
    );
};

export default withI18n()(OnlyOnApp);
