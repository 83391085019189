import { combineReducers } from "redux";
import festivalList from "./festivalList";
import festivalStage from "./festivalStage";
import global from "./global";

export default combineReducers({
    festivalList,
    festivalStage,
    global
});
