/**
 * Portuguese i18n strings.
 */

export default {
    translations: {
        festivals: "Festivais",
        exit_site: "Voltar ao site",
        previous_festivals: "Festivais finalizados",
        info: "Informações",
        discover: "Descubra",
        ranking: "Ranking",
        stats: "Estatísticas",
        attendees: "Público",
        discover_ad:
            "Descubra o festival {{festival}}. Notícias, notificações de novas cervejas engatadas, destaques e mais. Funcionalidade apenas disponível no aplicativo Brewer Stage. Baixe agora.",
        countdown_days: "DIAS",
        countdown_hours: "HORAS",
        countdown_minutes: "MIN.",
        countdown_seconds: "SEG.",
        loading: "Carregando...",
        search_placeholder: "Pesquisar...",
        jan: "Jan",
        feb: "Fev",
        mar: "Mar",
        apr: "Abr",
        may: "Mai",
        jun: "Jun",
        jul: "Jul",
        aug: "Ago",
        sep: "Set",
        oct: "Out",
        nov: "Nov",
        dec: "Dez",
        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may_full: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro",
        tba: "A definir",
        festival_list_title: "Festas e Festivais Cervejeiros {{year}}",
        festival_list_desc:
            "Acompanhe os melhores festivais cervejeiros de {{year}}. Melhores cervejas e cervejarias, notícias e destaques.",
        info_title: "Informações Práticas",
        info_desc:
            "Informações gerais do festival {{festival}}. Ingressos, localização, cervejarias e rotulos confirmados.",
        global_position: "Posição geral",
        weighted_avg_score: "Nota média ajustada",
        simple_avg: "Média simples",
        learn: "Entenda",
        checkins: "Check-ins",
        rating_scores: "Avaliações",
        biggest_fans: "Maiores fãs",
        empty_beer_search: "Nenhuma cerveja para exibir.",
        empty_beer_ranking:
            "Nenhuma cerveja atingiu o mínimo de check-ins para aparecer nesta lista.",
        beer_ranking_title: "Melhores Cervejas",
        beer_ranking_desc:
            "Melhores cervejas do {{festival}}, segundo avaliação do público.",
        sharing_img: "Imagem para compartilhar",
        empty_brewery_ranking:
            "Nenhuma cervejaria atingiu o mínimo de check-ins para aparecer nesta lista.",
        weighted_avg: "Média ajustada",
        beer_count: "{{count}} rótulo",
        beer_count_plural: "{{count}} rótulos",
        brewery_ranking_title: "Melhores Cervejarias",
        brewery_ranking_desc:
            "Melhores cervejarias do {{festival}}, segundo avaliação do público.",
        share: "Compartilhar",
        beer_share_message:
            "Veja as melhores {{style}} do festival {{festival_name}}, de acordo com todos os check-ins realizados no aplicativo Untappd. Mais em: {{url}}",
        beer_share_title: "Melhores {{style}} - {{festival_name}}",
        beers: "cervejas",
        beers_tab: "Cervejas",
        breweries_tab: "Cervejarias",
        checkins_total: "Número de check-ins",
        checkins_brewery: "Check-ins por cervejaria",
        festival_average: "Nota média do festival",
        users: "Usuários",
        last_update: "Última atualização",
        stats_title: "Estatísticas",
        stats_desc:
            "Estatísticas do {{festival}}. Total de avaliações do público, por cervejaria e por estilo de cerveja.",
        late_checkins_message:
            "Festival finalizado! Aguardando late check-ins.",
        no_user_ranking: "Ninguém ainda entrou na lista.",
        rated_beer_count: "1 cerveja avaliada",
        rated_beer_count_plural: "{{count}} diferentes cervejas avaliadas",
        top_users_title: "Público",
        top_users_desc:
            "Participantes do {{festival}} com maior número de avaliações.",
        countdown_alert:
            "Os rankings e estatísticas só estarão disponíveis após o início do festival.",
        old_festivals_title: "Festivais Cervejeiros",
        old_festivals_desc:
            "Melhores cervejas e cervejarias dos festivais cervejeiros.",
        new_releases: "Lançamentos",
        all_beers: "Todas as cervejas",
        country_AD: "Andorra",
        country_AE: "Emirados Árabes",
        country_AF: "Afeganistão",
        country_AG: "Antígua e Barbuda",
        country_AI: "Anguilla",
        country_AL: "Albânia",
        country_AM: "Armênia",
        country_AN: "Antilhas Holandesas",
        country_AO: "Angola",
        country_AQ: "Antártida",
        country_AR: "Argentina",
        country_AS: "Samoa Americana",
        country_AT: "Áustria",
        country_AU: "Austrália",
        country_AW: "Aruba",
        country_AX: "Ilhas Åland",
        country_AZ: "Azerbaijão",
        country_BA: "Bósnia e Herzegovina",
        country_BB: "Barbados",
        country_BD: "Bangladesh",
        country_BE: "Bélgica",
        country_BF: "Burkina Faso",
        country_BG: "Bulgária",
        country_BH: "Bahrein",
        country_BI: "Burundi",
        country_BJ: "Benim",
        country_BL: "São Bartolomeu",
        country_BM: "Bermudas",
        country_BN: "Brunei",
        country_BO: "Bolívia",
        country_BR: "Brasil",
        country_BS: "Bahamas",
        country_BT: "Butão",
        country_BV: "Ilha Bouvet",
        country_BW: "Botswana",
        country_BY: "Bielorrússia",
        country_BZ: "Belize",
        country_CA: "Canadá",
        country_CC: "Ilhas Cocos (Keeling)",
        country_CD: "República Democrática do Congo",
        country_CF: "República Centro-Africana",
        country_CG: "República do Congo",
        country_CH: "Suíça",
        country_CI: "Costa do Marfim",
        country_CK: "Ilhas Cook",
        country_CL: "Chile",
        country_CM: "Camarões",
        country_CN: "China",
        country_CO: "Colômbia",
        country_CR: "Costa Rica",
        country_CU: "Cuba",
        country_CV: "Cabo Verde",
        country_CX: "Ilha do Natal",
        country_CY: "Chipre",
        country_CZ: "República Checa",
        country_DE: "Alemanha",
        country_DJ: "Djibouti",
        country_DK: "Dinamarca",
        country_DM: "Dominica",
        country_DO: "República Dominicana",
        country_DZ: "Argélia",
        country_EC: "Equador",
        country_EE: "Estónia",
        country_EG: "Egito",
        country_EH: "Saara Ocidental",
        country_ER: "Eritreia",
        country_ES: "Espanha",
        country_ET: "Etiópia",
        country_FI: "Finlândia",
        country_FJ: "Fiji",
        country_FK: "Ilhas Malvinas",
        country_FM: "Estados Federados da Micronésia",
        country_FO: "Ilhas Feroé",
        country_FR: "França",
        country_GA: "Gabão",
        country_GB: "Reino Unido",
        country_GD: "Granada",
        country_GE: "Geórgia",
        country_GF: "Guiana Francesa",
        country_GG: "Guernsey",
        country_GH: "Gana",
        country_GI: "Gibraltar",
        country_GL: "Gronelândia",
        country_GM: "Gâmbia",
        country_GN: "Guiné",
        country_GP: "Guadalupe",
        country_GQ: "Guiné Equatorial",
        country_GR: "Grécia",
        country_GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
        country_GT: "Guatemala",
        country_GU: "Guam",
        country_GW: "Guiné-Bissau",
        country_GY: "Guiana",
        country_HK: "Hong Kong",
        country_HM: "Ilha Heard e Ilhas McDonald",
        country_HN: "Honduras",
        country_HR: "Croácia",
        country_HT: "Haiti",
        country_HU: "Hungria",
        country_ID: "Indonésia",
        country_IE: "Irlanda",
        country_IL: "Israel",
        country_IM: "Ilha de Man",
        country_IN: "Índia",
        country_IO: "Território Britânico do Oceano Índico",
        country_IQ: "Iraque",
        country_IR: "Irão",
        country_IS: "Islândia",
        country_IT: "Itália",
        country_JE: "Jersey",
        country_JM: "Jamaica",
        country_JO: "Jordânia",
        country_JP: "Japão",
        country_KE: "Quênia",
        country_KG: "Quirguistão",
        country_KH: "Camboja",
        country_KI: "Kiribati",
        country_KM: "Comores",
        country_KN: "São Cristóvão e Névis",
        country_KP: "Coreia do Norte",
        country_KR: "Coreia do Sul",
        country_KW: "Kuwait",
        country_KY: "Ilhas Cayman",
        country_KZ: "Cazaquistão",
        country_LA: "Laos",
        country_LB: "Líbano",
        country_LC: "Santa Lúcia",
        country_LI: "Liechtenstein",
        country_LK: "Sri Lanka",
        country_LR: "Libéria",
        country_LS: "Lesoto",
        country_LT: "Lituânia",
        country_LU: "Luxemburgo",
        country_LV: "Letônia",
        country_LY: "Líbia",
        country_MA: "Marrocos",
        country_MC: "Mónaco",
        country_MD: "Moldávia",
        country_ME: "Montenegro",
        country_MF: "São Martinho",
        country_MG: "Madagáscar",
        country_MH: "Ilhas Marshall",
        country_MK: "Macedônia",
        country_ML: "Mali",
        country_MM: "Mianmar",
        country_MN: "Mongólia",
        country_MO: "Macau",
        country_MP: "Marianas Setentrionais",
        country_MQ: "Martinica",
        country_MR: "Mauritânia",
        country_MS: "Montserrat",
        country_MT: "Malta",
        country_MU: "Maurícia",
        country_MV: "Maldivas",
        country_MW: "Malawi",
        country_MX: "México",
        country_MY: "Malásia",
        country_MZ: "Moçambique",
        country_NA: "Namíbia",
        country_NC: "Nova Caledônia",
        country_NE: "Níger",
        country_NF: "Ilha Norfolk",
        country_NG: "Nigéria",
        country_NI: "Nicarágua",
        country_NL: "Países Baixos",
        country_NO: "Noruega",
        country_NP: "Nepal",
        country_NR: "Nauru",
        country_NU: "Niue",
        country_NZ: "Nova Zelândia",
        country_OM: "Omã",
        country_PA: "Panamá",
        country_PE: "Peru",
        country_PF: "Polinésia Francesa",
        country_PG: "Papua-Nova Guiné",
        country_PH: "Filipinas",
        country_PK: "Paquistão",
        country_PL: "Polónia",
        country_PM: "Saint-Pierre e Miquelon",
        country_PN: "Pitcairn",
        country_PR: "Porto Rico",
        country_PS: "Palestina",
        country_PT: "Portugal",
        country_PW: "Palau",
        country_PY: "Paraguai",
        country_QA: "Catar",
        country_RE: "Reunião",
        country_RO: "Roménia",
        country_RS: "Sérvia",
        country_RU: "Rússia",
        country_RW: "Ruanda",
        country_SA: "Arábia Saudita",
        country_SB: "Ilhas Salomão",
        country_SC: "Seicheles",
        country_SD: "Sudão",
        country_SE: "Suécia",
        country_SG: "Singapura",
        country_SH: "Santa Helena, Ascensão e Tristão da Cunha",
        country_SI: "Eslovênia",
        country_SJ: "Svalbard e Jan Mayen",
        country_SK: "Eslováquia",
        country_SL: "Serra Leoa",
        country_SM: "San Marino",
        country_SN: "Senegal",
        country_SO: "Somália",
        country_SR: "Suriname",
        country_ST: "São Tomé e Príncipe",
        country_SV: "El Salvador",
        country_SY: "Síria",
        country_SZ: "Suazilândia",
        country_TC: "Turks e Caicos",
        country_TD: "Chade",
        country_TF: "Terras Austrais e Antárticas Francesas",
        country_TG: "Togo",
        country_TH: "Tailândia",
        country_TJ: "Tajiquistão",
        country_TK: "Toquelau",
        country_TL: "Timor-Leste",
        country_TM: "Turquemenistão",
        country_TN: "Tunísia",
        country_TO: "Tonga",
        country_TR: "Turquia",
        country_TT: "Trinidad e Tobago",
        country_TV: "Tuvalu",
        country_TW: "Taiwan",
        country_TZ: "Tanzânia",
        country_UA: "Ucrânia",
        country_UG: "Uganda",
        country_UM: "Ilhas Menores Distantes dos Estados Unidos",
        country_US: "Estados Unidos",
        country_UY: "Uruguai",
        country_UZ: "Uzbequistão",
        country_VA: "Vaticano",
        country_VC: "São Vicente e Granadinas",
        country_VE: "Venezuela",
        country_VG: "Ilhas Virgens Britânicas",
        country_VI: "Ilhas Virgens Americanas",
        country_VN: "Vietnã",
        country_VU: "Vanuatu",
        country_WF: "Wallis e Futuna",
        country_WS: "Samoa",
        country_YE: "Iêmen",
        country_YT: "Mayotte",
        country_ZA: "África do Sul",
        country_ZM: "Zâmbia",
        country_ZW: "Zimbabwe",
        beer_list: "Cervejas"
    }
};
