import React, { Component } from "react";

import classes from "./Festival.css";
import LiveLabel from "./LiveLabel/LiveLabel";
import Auxi from "../../../hoc/Auxi";
import { withI18n } from "react-i18next";

class Festival extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.months = [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may_full"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december")
        ];
    }

    renderDate() {
        const { t } = this.props;
        let day1 = t("tba");
        let month1 = "";
        let day2, month2;
        if (this.props.startDate) {
            day1 = this.props.startDate.substring(8, 10);
            month1 = this.props.startDate.substring(5, 7);
            month1 = this.months[parseInt(month1, 10) - 1];
            if (this.props.endDate) {
                month2 = this.props.endDate.substring(5, 7);
                month2 = this.months[parseInt(month2, 10) - 1];
                if (month1 === month2) {
                    day1 += " - " + this.props.endDate.substring(8, 10);
                } else {
                    day2 = this.props.endDate.substring(8, 10);
                }
            }
        }
        return (
            <div className={classes.DateContainer}>
                <div className={classes.Date}>
                    <div className={classes.Day}>{day1}</div>
                    <div className={classes.Month}>{month1}</div>
                </div>
                {day2 ? (
                    <Auxi>
                        <div className={classes.Date}>-</div>
                        <div className={classes.Date}>
                            <div className={classes.Day}>{day2}</div>
                            <div className={classes.Month}>{month2}</div>
                        </div>
                    </Auxi>
                ) : null}
            </div>
        );
    }

    render() {
        var divStyle = {
            backgroundImage: "url(" + this.props.img + ")"
        };

        let live = null;
        if (this.props.live) {
            live = <LiveLabel />;
        }

        let festivalClasses = classes.Festival;
        if (this.props.highlight) {
            festivalClasses = [classes.Festival, classes.Highlight].join(" ");
        }

        return (
            <div
                className={festivalClasses}
                style={divStyle}
                onClick={this.props.clicked}
            >
                {live}
                <div className={classes.Wrapper}>
                    {this.renderDate()}
                    <div className={classes.Data}>
                        <div>{this.props.name}</div>
                        <div className={classes.Location}>
                            {this.props.location}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withI18n()(Festival);
