import React from "react";
import classes from "./StatsBox.css";

const col = props => (
    <div className={classes.Col}>
        <h1>{props.title}</h1>
        {props.children}
    </div>
);

const statsBox = props => (
    <div className={classes.StatsBox}>
        <div
            className={[
                classes.StatsBoxContainer,
                props.row ? classes.Row : []
            ].join(" ")}
        >
            {props.title ? <h1>{props.title}</h1> : null}
            {props.children}
        </div>
    </div>
);

export default statsBox;
export { col as Col };
