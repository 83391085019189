import React from "react";
import classes from "./Loader.css";
import { withI18n } from "react-i18next";

const loader = props => {
    const { t } = props;
    return <div className={classes.Loader}>{t("loading")}</div>;
};

export default withI18n()(loader);
