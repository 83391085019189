import React, { PureComponent } from "react";
import { withI18n } from "react-i18next";

import classes from "./FestivalInfoHeader.css";

class FestivalInfoHeader extends PureComponent {
    render() {
        const { t } = this.props;
        return (
            <div
                style={{
                    backgroundImage: "url(" + this.props.festival.img + ")"
                }}
                className={classes.Header}
            >
                <div className={classes.FestivalTitle}>
                    <img
                        alt={this.props.festival.name}
                        className={classes.Avatar}
                        src={this.props.festival.avatar}
                    />
                    <div className={classes.FestivalTitleData}>
                        <div className={classes.HeaderText}>
                            {this.props.festival.name}
                        </div>
                        <div className={classes.HeaderTextLocation}>
                            {this.props.festival.location},{" "}
                            {t("country_" + this.props.festival.country)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withI18n()(FestivalInfoHeader);
