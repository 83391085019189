import React, { Component } from "react";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import portugueseStrings from "react-timeago/lib/language-strings/pt";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import classes from "./Stats.css";
import MetaTags from "react-meta-tags";
import Loader from "../../../components/UI/Loader/Loader";
import StatsBox, { Col } from "./StatsBox/StatsBox";
import NumberStats from "./NumberStats/NumberStats";
import Auxi from "../../../hoc/Auxi";
import HistogramChart from "./HistogramChart/HistogramChart";
import BreweriesChart from "./BreweriesChart/BreweriesChart";
import FestivalQualityChart from "./FestivalQualityChart/FestivalQualityChart";
import * as actions from "../../../store/actions/index";
import Message from "../../../components/UI/Message/Message";
import StylesChart from "./StylesChart/StylesChart";
import { withI18n } from "react-i18next";

class TopUsers extends Component {
    componentDidMount() {
        this.props.loadStats(this.props.festivalId);
    }

    componentWillUnmount() {
        actions.unloadStats(this.props.festivalId);
    }

    render() {
        const { t } = this.props;
        const formatter = buildFormatter(portugueseStrings);
        let stats = <Loader />;
        if (this.props.stats) {
            stats = (
                <Auxi>
                    {this.props.stats.histogram ? (
                        <StatsBox title={t("checkins_total")}>
                            <NumberStats>
                                {this.props.stats.checkins_count}
                            </NumberStats>
                            <HistogramChart
                                histogram={this.props.stats.histogram}
                            />
                        </StatsBox>
                    ) : null}
                    {this.props.stats.breweries ? (
                        <StatsBox title={t("checkins_brewery")}>
                            <BreweriesChart
                                breweries={this.props.stats.breweries}
                            />
                        </StatsBox>
                    ) : null}
                    {this.props.stats.average ? (
                        <StatsBox title={t("festival_average")}>
                            <FestivalQualityChart
                                ratingScore={this.props.stats.average}
                            />
                        </StatsBox>
                    ) : null}
                    <StatsBox row>
                        <Col title={t("beers_tab")}>
                            <NumberStats small>
                                {this.props.stats.beers_count
                                    ? this.props.stats.beers_count
                                    : 0}
                            </NumberStats>
                        </Col>
                        <Col title={t("breweries_tab")}>
                            <NumberStats small>
                                {this.props.stats.breweries_count
                                    ? this.props.stats.breweries_count
                                    : 0}
                            </NumberStats>
                        </Col>
                        <Col title={t("users")}>
                            <NumberStats small>
                                {this.props.stats.users_count
                                    ? this.props.stats.users_count
                                    : 0}
                            </NumberStats>
                        </Col>
                    </StatsBox>
                    {this.props.stats.styles && false ? (
                        <StatsBox
                            title={
                                this.props.stats.styles[0].style_name +
                                " vs. " +
                                this.props.stats.styles[1].style_name
                            }
                        >
                            <StylesChart data={this.props.stats.styles} />
                        </StatsBox>
                    ) : null}
                    {this.props.festival.status !== "ended" ? (
                        <StatsBox title={t("last_update")}>
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "1.5em"
                                }}
                            >
                                <TimeAgo
                                    date={Date.parse(
                                        this.props.stats.last_update
                                    )}
                                    formatter={formatter}
                                    live
                                />
                            </div>
                        </StatsBox>
                    ) : null}
                </Auxi>
            );
        }
        return (
            <Auxi>
                <MetaTags>
                    <title>
                        {this.props.festival.name} | {t("stats_title")} | Brewer
                        Stage
                    </title>
                    <meta
                        name="description"
                        content={t("stats_desc", {
                            festival: this.props.festival.name
                        })}
                    />
                </MetaTags>
                {this.props.festival.status === "late_checkins" ? (
                    <Message>{t("late_checkins_message")}</Message>
                ) : null}
                <div className={classes.Stats}>{stats}</div>
            </Auxi>
        );
    }
}

const mapStateToProps = state => {
    return {
        stats: state.festivalStage.stats,
        festival: state.festivalStage.currentFestival
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadStats: id => dispatch(actions.loadStats(id))
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TopUsers)
);
