import React from "react";
import classes from "./RankingItem.css";

const RankingItem = props => {
    let img = null;
    if (props.img) {
        let imgClasses = classes.Image;
        if (props.roundImage) {
            imgClasses += " " + classes.Round;
        }
        img = (
            <div className={imgClasses}>
                <img
                    src={props.img}
                    onError={e => {
                        e.target.src = props.default_img;
                    }}
                    alt={props.name}
                />
            </div>
        );
    }

    let rankingItemClasses = classes.RankingItem;

    if (props.click) {
        rankingItemClasses = [rankingItemClasses, classes.Link].join(" ");
    }

    return (
        <div
            className={rankingItemClasses}
            key={props.id}
            onClick={props.click}
        >
            <div className={classes.Position}>{props.position}</div>
            <div className={classes.Data}>
                <div className={classes.Name}>{props.name}</div>
                <div className={classes.Subtitle}>{props.subtitle}</div>
                <div className={classes.Details}>{props.details}</div>
            </div>
            {img}
        </div>
    );
};

export default RankingItem;
