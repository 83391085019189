import React, { Component } from "react";
import classes from "./FestivalBeers.css";
import Loader from "../../../components/UI/Loader/Loader";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

class FestivalBeers extends Component {
    componentDidMount() {
        this.props.loadBeerList(this.props.festivalId);
    }

    componentWillUnmount() {
        actions.unloadBeerList();
    }

    render() {
        const { beerList } = this.props;
        let lastBrewery = null;
        if (!beerList) return <Loader />;
        return (
            <div className={classes.BeerListContainer}>
                <div className={classes.BeerList}>
                    {beerList.map(beer => {
                        const beerCard = (
                            <div key={beer.id} className={classes.Beer}>
                                <div className={classes.BeerHeader}>
                                    <img
                                        className={classes.BeerImage}
                                        alt={beer.name}
                                        src={beer.img}
                                    />
                                    <div className={classes.BeerInfo}>
                                        <div className={classes.BeerName}>
                                            {beer.name}
                                        </div>
                                        <div className={classes.BreweryName}>
                                            {beer.brewery}
                                        </div>
                                        <div className={classes.BeerDetails}>
                                            {beer.style}
                                            {beer.ibu
                                                ? ` / ${beer.ibu} IBUs`
                                                : null}
                                            {beer.abv
                                                ? ` / ${beer.abv}% ABV`
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                {beer.description && (
                                    <div className={classes.BeerDescription}>
                                        {beer.description}
                                    </div>
                                )}
                            </div>
                        );
                        if (lastBrewery !== beer.brewery) {
                            lastBrewery = beer.brewery;
                            return (
                                <React.Fragment key={beer.id}>
                                    <h3>{beer.brewery}</h3>
                                    {beerCard}
                                </React.Fragment>
                            );
                        }
                        return beerCard;
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        beerList: state.festivalStage.beerList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadBeerList: festivalId => dispatch(actions.loadBeerList(festivalId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FestivalBeers);
