import React from "react";
import classes from "./Toolbar.css";
import Logo from "../../Logo/Logo";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import NavigationItems from "../NavigationItems/NavigationItems";

const toolbar = props => (
    <header className={classes.Header}>
        <div className={classes.Toolbar}>
            <DrawerToggle click={props.menuClick} />
            <div className={classes.Logo}>
                <Logo />
            </div>
            <div style={{ width: "40px" }} />
            <nav className={classes.DesktopOnly}>
                <NavigationItems />
            </nav>
        </div>
    </header>
);

export default toolbar;
