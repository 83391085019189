import React, { Component } from "react";
import classes from "./RankingImageShare.css";
import ActionButtons from "../../../../components/UI/ActionButtons/ActionButtons";
import ActionButton from "../../../../components/UI/ActionButtons/ActionButton/ActionButton";
import { withI18n } from "react-i18next";
import i18next from "i18next";

class RankingImageShare extends Component {
    componentDidMount() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId: "180478095997787",
                autoLogAppEvents: true,
                xfbml: true,
                version: "v3.0"
            });
        };

        (function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }

    downloadClickHandler = imageUrl => {
        window.location = imageUrl + "&d=download";
    };

    facebookClickHandler = (title, message, url, imageUrl) => {
        window.FB.ui(
            {
                method: "share_open_graph",
                action_type: "og.shares",
                action_properties: JSON.stringify({
                    object: {
                        "og:url": url,
                        "og:title": title,
                        "og:description": message,
                        "og:image": imageUrl
                    }
                })
            },
            response => {}
        );
    };

    render() {
        const { t } = this.props;

        if (this.props.festival) {
            // Image URL
            let imageUrl = `https://us-central1-brewer-stage.cloudfunctions.net/beerRankingImage?f=${this.props.festival.id}&l=${i18next.language}`;
            if (this.props.selectedStyle) {
                imageUrl += "&s=" + this.props.selectedStyle;
            }
            // Title
            const title = t("beer_share_title", {
                style: this.props.selectedStyle
                    ? this.props.selectedStyle
                    : t("beers"),
                festival_name: this.props.festival.name
            });
            // URL
            const url = `https://app.brewerstage.com/festival/${this.props.festival.id}/ranking/beers`;
            // Message
            const message = t("beer_share_message", {
                style: this.props.selectedStyle
                    ? this.props.selectedStyle
                    : t("beers"),
                festival_name: this.props.festival.name,
                url
            });
            return (
                <div className={classes.ShareImageContainer}>
                    <img
                        src={imageUrl}
                        alt={title}
                        className={classes.ShareImage}
                    />
                    <ActionButtons>
                        <ActionButton
                            title="Facebook"
                            icon="facebook-f"
                            iconClassName="fab"
                            onClick={() =>
                                this.facebookClickHandler(
                                    title,
                                    message,
                                    url,
                                    imageUrl
                                )
                            }
                        />
                        <ActionButton
                            title="Download"
                            icon="download"
                            onClick={() => this.downloadClickHandler(imageUrl)}
                        />
                    </ActionButtons>
                </div>
            );
        }

        return <div />;
    }
}

export default withI18n()(RankingImageShare);
