import React from "react";
import classes from "./BeerInfo.css";
import InfoLink from "../../../../../components/UI/InfoLink/InfoLink";
import { withI18n } from "react-i18next";

const beerInfo = props => {
    const { t } = props;

    if (props.beer) {
        return (
            <div className={classes.BeerInfo}>
                <div className={classes.Header}>
                    <div className={classes.Image}>
                        <img src={props.beer.img} alt={props.beer.beer} />
                    </div>
                    <div className={classes.Info}>
                        <div className={classes.Name}>{props.beer.beer}</div>
                        <div className={classes.Brewery}>
                            {props.beer.brewery}
                        </div>
                        <div className={classes.Style}>{props.beer.style}</div>
                    </div>
                </div>
                <div className={classes.Body}>
                    <div className={classes.Stat}>
                        <div className={classes.Title}>
                            {t("global_position")}
                        </div>
                        <div className={classes.Value}>
                            {props.beer.position}
                        </div>
                    </div>
                    <div className={classes.Stat}>
                        <div className={classes.Title}>
                            {t("weighted_avg_score")}
                        </div>
                        <div className={classes.Value}>
                            {props.beer.weighted_rating.toFixed(2)}{" "}
                            <small>
                                {t("simple_avg")}{" "}
                                {props.beer.avg_rate.toFixed(2)}{" "}
                                <InfoLink href="https://brewerstage.com/how_ranking_works/">
                                    {t("learn")}
                                </InfoLink>
                            </small>
                        </div>
                    </div>
                    <div className={classes.Stat}>
                        <div className={classes.Title}>{t("checkins")}</div>
                        <div className={classes.Value}>
                            {props.beer.num_checkins}
                        </div>
                    </div>
                    <div className={classes.Stat}>
                        <div className={classes.Title}>
                            {t("rating_scores")}
                        </div>
                        <div className={classes.Value}>
                            {props.beer.num_rates}
                        </div>
                    </div>
                    <div className={classes.Stat} style={{ width: "100%" }}>
                        <div className={classes.Title}>{t("biggest_fans")}</div>
                        <div className={classes.Users}>
                            {props.beer.users.map(user => (
                                <div
                                    className={classes.User}
                                    key={user.user_name}
                                >
                                    <div className={classes.Avatar}>
                                        <img
                                            src={
                                                user.user_avatar
                                                    ? user.user_avatar
                                                    : "https://i1.wp.com/untappd.akamaized.net/site/assets/images/default_avatar_v2_gravatar.jpg"
                                            }
                                            onError={e => {
                                                e.target.src =
                                                    "https://i1.wp.com/untappd.akamaized.net/site/assets/images/default_avatar_v2_gravatar.jpg";
                                            }}
                                            alt={user.user_name}
                                        />
                                    </div>
                                    {user.user_name}
                                    <br />
                                    <strong>{user.rating_score}</strong> (
                                    {user.count +
                                        " " +
                                        (user.count === 1
                                            ? "check-in"
                                            : "check-ins")}
                                    )
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <div />;
};

export default withI18n()(beerInfo);
