import React from "react";
import FontAwesome from "react-fontawesome";

import classes from "./InfoLink.css";

const infoLink = props => (
    <div className={classes.InfoLinkContainer}>
        <div className={classes.InfoLink}>
            <FontAwesome name="info" style={{ marginRight: 4 }} />
            <a target="_blank" rel="noopener noreferrer" href={props.href}>
                {props.children}
            </a>
        </div>
    </div>
);

export default infoLink;
