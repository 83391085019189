import React from "react";
import FontAwesome from "react-fontawesome";
import { NavLink } from "react-router-dom";

import classes from "./ToolbarIcon.css";

const toolbarIcon = props => {
    return (
        <div>
            <NavLink
                className={classes.Link}
                activeClassName={classes.Active}
                to={props.link}
                onClick={() => {
                    return window.scrollTo(0, 0);
                }}
            >
                <div className={classes.Icon}>
                    <FontAwesome name={props.icon} />
                </div>
                <div className={classes.Label}>{props.label}</div>
            </NavLink>
        </div>
    );
};

export default toolbarIcon;
