import firebase from "firebase";

var config = {
    apiKey: "AIzaSyBdg_nHzkjJ50OvO4EXMORa0Nfu3KbIOwM",
    authDomain: "brewer-stage.firebaseapp.com",
    databaseURL: "https://brewer-stage.firebaseio.com/",
    storageBucket: "brewer-stage.appspot.com"
};
// var config = {
//     apiKey: "AIzaSyAcK0TBGuBNaBwQ2orkDrFfP2AyubowAaA",
//     authDomain: "dev-brewer-stage.firebaseapp.com",
//     databaseURL: "https://dev-brewer-stage.firebaseio.com/",
//     storageBucket: "dev-brewer-stage.appspot.com"
// };
firebase.initializeApp(config);

var database = firebase.database();

export default database;
