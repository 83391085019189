import React, { Component } from "react";
import Layout from "./containers/Layout/Layout";
import FestivalList from "./containers/FestivalList/FestivalList";
import OldFestivalList from "./containers/OldFestivalList/OldFestivalList";
import { Route, Switch, Redirect } from "react-router-dom";
import FestivalStage from "./containers/FestivalStage/FestivalStage";
import classes from "./App.css";

class App extends Component {
    render() {
        let redirect = <Redirect from="/" exact to="/festivals" />;
        let currentFestivalId = localStorage.getItem("current_festival_id");
        if (currentFestivalId) {
            redirect = (
                <Redirect
                    from="/"
                    exact
                    to={"/festival/" + currentFestivalId}
                />
            );
        }

        return (
            <div className={classes.Container}>
                <Layout>
                    <Switch>
                        {redirect}
                        <Route
                            path="/festivals/old"
                            exact
                            component={OldFestivalList}
                        />
                        <Route
                            path="/festivals"
                            exact
                            component={FestivalList}
                        />
                        <Route path="/festival/:id" component={FestivalStage} />
                        <Route render={() => <h1>Not found</h1>} />
                    </Switch>
                </Layout>
            </div>
        );
    }
}

export default App;
