import firebase from "../../firebase-bs";

import * as actionTypes from "./actionTypes";

export const selectFestival = festivalInfo => {
    localStorage.setItem("current_festival_id", festivalInfo.id);
    return {
        type: actionTypes.SELECT_FESTIVAL,
        festivalInfo: festivalInfo
    };
};

export const exitFestival = () => {
    localStorage.removeItem("current_festival_id");
    return {
        type: actionTypes.EXIT_FESTIVAL
    };
};

export const setFestivalConfig = config => {
    return {
        type: actionTypes.SET_FESTIVAL_CONFIG,
        festivalConfig: config ? config : {}
    };
};

export const loadFestivalInfo = id => {
    return dispatch => {
        const festivalInfoRef = firebase.ref("/festivals/" + id + "/info");
        const festivalConfigRef = firebase.ref("/festivals/" + id + "/config");
        festivalInfoRef.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(selectFestival(snapshot.val()));
            }
        });
        festivalConfigRef.on("value", snapshot => {
            dispatch(setFestivalConfig(snapshot.val()));
        });
    };
};

export const unloadFestivalInfo = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/info").off();
};

export const setBeerRanking = ranking => {
    return {
        type: actionTypes.SET_BEER_RANKING,
        ranking: ranking
    };
};

export const loadBeerRanking = festivalId => {
    return dispatch => {
        const beerRankingRef = firebase.ref(
            "/festivals/" + festivalId + "/ranking/beers"
        );
        beerRankingRef.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setBeerRanking(snapshot.val()));
            } else {
                dispatch(setBeerRanking([]));
            }
        });
    };
};

export const unloadRanking = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/ranking/beers").off();
    firebase.ref("/festivals/" + festivalId + "/ranking/breweries").off();
};

export const setBeerList = beerList => {
    return {
        type: actionTypes.SET_BEER_LIST,
        beerList
    };
};

export const loadBeerList = festivalId => {
    return dispatch => {
        const ref = firebase.ref(
            "/festivals/" + festivalId + "/lineup/beers/all"
        );
        ref.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setBeerList(snapshot.val()));
            } else {
                dispatch(setBeerList([]));
            }
        });
    };
};

export const unloadBeerList = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/lineup/beers/all").off();
};

export const setBreweryLineup = breweryLineup => {
    return {
        type: actionTypes.SET_BREWERY_LINEUP,
        breweryLineup
    };
};

export const loadBreweryLineup = festivalId => {
    return dispatch => {
        const ref = firebase.ref(
            "/festivals/" + festivalId + "/lineup/breweries/all"
        );
        ref.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setBreweryLineup(snapshot.val()));
            } else {
                dispatch(setBreweryLineup([]));
            }
        });
    };
};

export const unloadBreweryLineup = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/lineup/breweries/all").off();
};

export const filterBeerRanking = terms => {
    return {
        type: actionTypes.FILTER_BEER_RANKING,
        terms: terms
    };
};

export const setBreweryRanking = ranking => {
    return {
        type: actionTypes.SET_BREWERY_RANKING,
        ranking: ranking
    };
};

export const loadBreweryRanking = festivalId => {
    return dispatch => {
        const breweriesRankingRef = firebase.ref(
            "/festivals/" + festivalId + "/ranking/breweries"
        );
        breweriesRankingRef.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setBreweryRanking(snapshot.val()));
            } else {
                dispatch(setBreweryRanking([]));
            }
        });
    };
};

export const setStats = stats => {
    return {
        type: actionTypes.SET_STATS,
        stats: stats
    };
};

export const loadStats = festivalId => {
    return dispatch => {
        const statsRef = firebase.ref("/festivals/" + festivalId + "/stats");
        statsRef.on("value", snapshot => {
            dispatch(setStats(snapshot.val()));
        });
    };
};

export const unloadStats = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/stats").off();
};

export const setTopUsers = users => {
    return {
        type: actionTypes.SET_TOP_USERS,
        users: users
    };
};

export const loadTopUsers = festivalId => {
    return dispatch => {
        const usersRef = firebase.ref(
            "/festivals/" + festivalId + "/ranking/users"
        );
        usersRef.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setTopUsers(snapshot.val()));
            } else {
                dispatch(setTopUsers([]));
            }
        });
    };
};

export const unloadTopUsers = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/ranking/users").off();
};

export const setStyles = styles => {
    return {
        type: actionTypes.SET_STYLES,
        styles: styles
    };
};

export const loadStyles = festivalId => {
    return dispatch => {
        const stylesRef = firebase.ref("/festivals/" + festivalId + "/styles");
        stylesRef.on("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setStyles(snapshot.val()));
            } else {
                dispatch(setStyles([]));
            }
        });
    };
};

export const unloadStyles = festivalId => {
    firebase.ref("/festivals/" + festivalId + "/styles").off();
};

export const setStyleGroups = styleGroups => {
    return {
        type: actionTypes.SET_STYLE_GROUPS,
        styleGroups: styleGroups
    };
};

export const loadStyleGroups = () => {
    return dispatch => {
        const styleGroupsRef = firebase.ref("/config/styles");
        styleGroupsRef.once("value", snapshot => {
            if (snapshot.val()) {
                dispatch(setStyleGroups(snapshot.val()));
            } else {
                dispatch(setStyleGroups([]));
            }
        });
    };
};

export const setSelectedStyle = selectedStyle => {
    return {
        type: actionTypes.SET_SELECTED_STYLE,
        selectedStyle: selectedStyle
    };
};
