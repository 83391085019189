import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Ranking from "./Ranking/Ranking";
import classes from "./FestivalStage.css";
import BottomToolbar from "../../components/Navigation/BottomToolbar/BottomToolbar";
import CountdownTimer from "../../components/UI/CountdownTimer/CountdownTimer";
import TopUsers from "./TopUsers/TopUsers";
import Stats from "./Stats/Stats";
import Loader from "../../components/UI/Loader/Loader";
import * as actions from "../../store/actions/index";
import FestivalInfo from "./FestivalInfo/FestivalInfo";
import SideToolbar from "../../components/Navigation/SideToolbar/SideToolbar";
import RankingPlaceholder from "./RankingPlaceholder/RankingPlaceholder";
import Message from "../../components/UI/Message/Message";
import OnlyOnApp from "../../components/OnlyOnApp/OnlyOnApp";
import { withI18n } from "react-i18next";
import FestivalBeers from "./FestivalBeers/FestivalBeers";

class FestivalStage extends Component {
    componentDidMount() {
        this.props.loadFestivalInfo(this.props.match.params.id);
        this.props.loadStylesGroup();
    }

    componentWillUnmount() {
        actions.unloadFestivalInfo(this.props.match.params.id);
    }

    render() {
        const { t } = this.props;
        let stage = <Loader />;

        let redirect = null;

        if (this.props.festival) {
            if (this.props.festival.status === "countdown") {
                redirect = (
                    <Redirect
                        from="/festival/:id/"
                        exact
                        to={"/festival/" + this.props.match.params.id + "/info"}
                    />
                );
            } else {
                redirect = (
                    <Redirect
                        from="/festival/:id/"
                        exact
                        to={
                            "/festival/" +
                            this.props.match.params.id +
                            "/ranking"
                        }
                    />
                );
            }
            stage = (
                <Switch>
                    <Route path="/festival/:id/info" component={FestivalInfo} />
                    <Route
                        path="/festival/:id/beer-list"
                        render={() => (
                            <FestivalBeers
                                festivalId={this.props.match.params.id}
                            />
                        )}
                    />
                    <Route
                        path="/festival/:id/ranking"
                        render={() => (
                            <Ranking festivalId={this.props.match.params.id} />
                        )}
                    />
                    <Route
                        path="/festival/:id/attendees"
                        exact
                        render={() => (
                            <TopUsers festivalId={this.props.match.params.id} />
                        )}
                    />
                    <Route
                        path="/festival/:id/stats"
                        exact
                        render={() => (
                            <Stats festivalId={this.props.match.params.id} />
                        )}
                    />
                    <Route
                        path="/festival/:id/discover"
                        exact
                        render={() => (
                            <OnlyOnApp
                                festival={this.props.festival}
                                message={t("discover_ad", {
                                    festival: this.props.festival.name
                                })}
                            />
                        )}
                    />
                    {redirect}
                </Switch>
            );

            if (
                this.props.festival.status === "countdown" &&
                new Date() < this.props.startTime &&
                !this.props.location.pathname.endsWith("/info") &&
                !this.props.location.pathname.endsWith("/beer-list")
            ) {
                stage = (
                    <RankingPlaceholder>
                        <Message>{t("countdown_alert")}</Message>
                        <CountdownTimer date={this.props.startTime} />
                    </RankingPlaceholder>
                );
            }
        }

        return (
            <div className={classes.StageContainer}>
                <SideToolbar />
                <div className={classes.Stage}>{stage}</div>
                <BottomToolbar />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        festival: state.festivalStage.currentFestival,
        startTime: state.festivalStage.startTime
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectFestival: festivalInfo =>
            dispatch(actions.selectFestival(festivalInfo)),
        loadStylesGroup: () => dispatch(actions.loadStyleGroups()),
        loadFestivalInfo: id => dispatch(actions.loadFestivalInfo(id))
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FestivalStage)
);
