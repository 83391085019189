import React from "react";

import FontAwesome from "react-fontawesome";
import NavigationItems from "../NavigationItems/NavigationItems";
import classes from "./SideDrawer.css";
import Auxi from "../../../hoc/Auxi";
import Backdrop from "../../UI/Backdrop/Backdrop";
import PoweredByUntappd from "../../PoweredByUntappd/PoweredByUntappd";
import logo from "../../../assets/images/brewer-stage-logo-drawer.png";

const sideDrawer = props => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <Auxi>
            <Backdrop show={props.open} clicked={props.closed} />
            <div className={attachedClasses.join(" ")}>
                <div className={classes.Logo}>
                    <img src={logo} alt="Brewer Stage App Logo" />
                </div>
                <nav>
                    <NavigationItems />
                </nav>
                <div className={classes.SocialIcons}>
                    <a href="https://brewerstage.com">
                        <FontAwesome name="globe" />
                    </a>
                    <a href="http://www.instagram.com/brewerstage">
                        <FontAwesome className="fab" name="instagram" />
                    </a>
                    <a href="https://www.facebook.com/brewerstage/">
                        <FontAwesome className="fab" name="facebook" />
                    </a>
                </div>
                <div className={classes.UntappdLogo}>
                    <PoweredByUntappd height="30px" />
                </div>
            </div>
        </Auxi>
    );
};

export default sideDrawer;
