import React from "react";

import classes from "./RankingPlaceholder.css";

const rankingPlaceholder = props => (
    <div
        className={[
            classes.RankingPlaceholderContainer,
            props.loading ? classes.Loading : ""
        ].join(" ")}
    >
        <div className={classes.RankingItem}>1. </div>
        <div className={classes.RankingItem}>2. </div>
        <div className={classes.RankingItem}>3. </div>
        <div className={classes.RankingItem}>4. </div>
        <div className={classes.RankingItem}>5. </div>
        <div className={classes.RankingItem}>6. </div>
        <div className={classes.RankingItem}>7. </div>
        <div className={classes.RankingItem}>8. </div>
        <div className={classes.RankingItem}>9. </div>
        <div className={classes.RankingItem}>10. </div>
        <div className={classes.CountdownContainer}>{props.children}</div>
    </div>
);

export default rankingPlaceholder;
