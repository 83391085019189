import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { withI18n } from "react-i18next";

class BreweriesChart extends Component {
    render() {
        const { t } = this.props;
        const chartData = {
            labels: Object.values(this.props.breweries).map(b => b.name),
            datasets: [
                {
                    backgroundColor: [
                        "#FF7029",
                        "#007C74",
                        "#0CC9BC",
                        "#491B04",
                        "#893308",
                        "#D6500D",
                        "#AF410A",
                        "#003D38",
                        "#00C9BB",
                        "#008980",
                        "#cccccc"
                    ],
                    label: t("breweries_tab"),
                    data: Object.values(this.props.breweries).map(b => b.count)
                }
            ]
        };
        const chartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            inGraphDataShow: true,
            tooltips: {
                show: true
            },
            legend: {
                display: true,
                position: "right",
                labels: {
                    fontSize: 10
                }
            }
        };

        return (
            <div style={{ maxWidth: 400, margin: "auto" }}>
                <Pie data={chartData} height={215} options={chartOptions} />
            </div>
        );
    }
}

export default withI18n()(BreweriesChart);
