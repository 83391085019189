import React, { Component } from "react";
import classes from "./SideToolbar.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SideToolbarItem from "./SideToolbarItem/SideToolbarItem";
import { withI18n } from "react-i18next";

class SideToolbar extends Component {
    render() {
        const { t, festival, config } = this.props;

        let avatar = <div className={classes.AvatarContainer} />;
        if (festival) {
            avatar = (
                <div className={classes.AvatarContainer}>
                    <img src={festival.avatar} alt={festival.name} />
                </div>
            );
        }

        return (
            <div className={classes.SideToolbar}>
                <div className={classes.ToolbarContainer}>
                    {avatar}
                    <div className={classes.ToolbarItems}>
                        <SideToolbarItem
                            link={
                                "/festival/" +
                                this.props.match.params.id +
                                "/info"
                            }
                            label={t("info")}
                            icon="info-circle"
                        />
                        <SideToolbarItem
                            link={
                                "/festival/" +
                                this.props.match.params.id +
                                "/discover"
                            }
                            label={t("discover")}
                            icon="compass"
                        />
                        {config && config.enable_beer_list && (
                            <SideToolbarItem
                                link={
                                    "/festival/" +
                                    this.props.match.params.id +
                                    "/beer-list"
                                }
                                label={t("beer_list")}
                                icon="beer"
                            />
                        )}
                        <SideToolbarItem
                            link={
                                "/festival/" +
                                this.props.match.params.id +
                                "/ranking"
                            }
                            label={t("ranking")}
                            icon="trophy"
                        />
                        <SideToolbarItem
                            link={
                                "/festival/" +
                                this.props.match.params.id +
                                "/stats"
                            }
                            label={t("stats")}
                            icon="chart-line"
                        />
                        <SideToolbarItem
                            link={
                                "/festival/" +
                                this.props.match.params.id +
                                "/attendees"
                            }
                            label={t("attendees")}
                            icon="users"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        festival: state.festivalStage.currentFestival,
        config: state.festivalStage.currentFestivalConfig
    };
};

export default withI18n()(
    withRouter(
        connect(
            mapStateToProps,
            null
        )(SideToolbar)
    )
);
