import React from "react";
import StaticCard from "./Cards/StaticCard";
import AnimatedCard from "./Cards/AnimatedCard";
import classes from "./FlipUnitContainer.css";

const flipUnitContainer = props => {
    let now = props.digit;
    let before = props.digit + 1;

    if (props.unit === "hours" && before > 23) {
        before = 0;
    } else if (
        (props.unit === "minutes" || props.unit === "seconds") &&
        before > 59
    ) {
        before = 0;
    }

    // add zero
    if (now < 10) now = `0${now}`;
    if (before < 10) before = `0${before}`;

    // shuffle digits
    const digit1 = props.shuffle ? before : now;
    const digit2 = !props.shuffle ? before : now;

    // shuffle animations
    const animation1 = props.shuffle ? classes.Fold : classes.Unfold;
    const animation2 = !props.shuffle ? classes.Fold : classes.Unfold;

    return (
        <div className={classes.FlipUnitContainer}>
            <StaticCard position={classes.UpperCard} digit={now} />
            <StaticCard position={classes.LowerCard} digit={before} />
            <AnimatedCard digit={digit1} animation={animation1} />
            <AnimatedCard digit={digit2} animation={animation2} />
        </div>
    );
};

export default flipUnitContainer;
