import React, { Component } from "react";
import { connect } from "react-redux";

import Auxi from "../../hoc/Auxi";
import classes from "./Layout.css";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import * as actions from "../../store/actions/index";

class Layout extends Component {
    render() {
        return (
            <Auxi>
                <div>
                    <Toolbar menuClick={this.props.openSideDrawer} />
                    <SideDrawer
                        open={this.props.showSideDrawer}
                        closed={this.props.closeSideDrawer}
                    />
                </div>
                <main className={classes.Content}>{this.props.children}</main>
            </Auxi>
        );
    }
}

const mapStateToProps = state => {
    return {
        showSideDrawer: state.global.showSideDrawer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openSideDrawer: () => dispatch(actions.openSideDrawer()),
        closeSideDrawer: () => dispatch(actions.closeSideDrawer())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
