import * as actionTypes from "../actions/actionTypes";

const initialState = {
    festivals: null,
    oldFestivals: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FESTIVAL_LIST:
            return {
                ...state,
                festivals: action.festivalList
            };
        case actionTypes.SET_OLD_FESTIVAL_LIST:
            return {
                ...state,
                oldFestivals: action.oldFestivalList
            };
        default:
            return state;
    }
};

export default reducer;
