import React from "react";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";

import classes from "./NavigationItems.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import Auxi from "../../../hoc/Auxi";
import * as actions from "../../../store/actions/index";
import { withI18n } from "react-i18next";

const navigationItems = props => {
    const { t } = props;

    let exitToSite = (
        <NavigationItem link="http://brewerstage.com">
            <FontAwesome name="sign-out-alt" /> {t("exit_site")}
        </NavigationItem>
    );

    if (window.matchMedia("(display-mode: standalone)").matches) {
        // Only show Exit to Site when not running in standalone mode.
        exitToSite = null;
    }

    let items = (
        <Auxi>
            <NavigationItem
                exact
                link="/festivals"
                onClick={props.exitFestival}
            >
                <FontAwesome name="home" /> {t("festivals")}
            </NavigationItem>
            <NavigationItem exact link="/festivals/old">
                <FontAwesome name="clock" /> {t("previous_festivals")}
            </NavigationItem>
            {exitToSite}
        </Auxi>
    );

    return <ul className={classes.NavigationItems}>{items}</ul>;
};

const mapStateToProps = state => {
    return {
        currentFestival: state.festivalStage.currentFestival
    };
};

const mapDispatchToProps = dispatch => {
    return {
        exitFestival: () => dispatch(actions.exitFestival())
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(navigationItems)
);
