import React from 'react';
import bsLogo from '../../assets/images/brewer-stage-logo.png';
import classes from './Logo.css';

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <img src={bsLogo} alt="Brewer Stage App" />
    </div>
);

export default logo;