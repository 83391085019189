import React from "react";
import FontAwesome from "react-fontawesome";

import classes from "./ActionButton.css";

const actionButton = props => (
    <div className={classes.ActionButton} onClick={props.onClick}>
        <span className={classes.Icon}>
            <FontAwesome className={props.iconClassName} name={props.icon} />
        </span>
        <span>{props.title}</span>
    </div>
);

export default actionButton;
