// Global
export const OPEN_SIDE_DRAWER = "OPEN_SIDE_DRAWER";
export const CLOSE_SIDE_DRAWER = "CLOSE_SIDE_DRAWER";
export const SET_ERROR_STATE = "SET_ERROR_STATE";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

// Festival List
export const SET_FESTIVAL_LIST = "SET_FESTIVAL_LIST";
export const SET_OLD_FESTIVAL_LIST = "SET_OLD_FESTIVAL_LIST";

// Festival Stage
export const SELECT_FESTIVAL = "SELECT_FESTIVAL";
export const EXIT_FESTIVAL = "EXIT_FESTIVAL";
export const SET_BEER_RANKING = "SET_BEER_RANKING";
export const FILTER_BEER_RANKING = "FILTER_BEER_RANKING";
export const SET_BREWERY_RANKING = "SET_BREWERY_RANKING";
export const SET_STATS = "SET_STATS";
export const SET_TOP_USERS = "SET_TOP_USERS";
export const UNLOAD_RANKING = "UNLOAD_RANKING";
export const UNLOAD_STATS = "UNLOAD_STATS";
export const UNLOAD_TOP_USERS = "UNLOAD_TOP_USERS";
export const SET_STYLES = "SET_STYLES";
export const UNLOAD_STYLES = "UNLOAD_STYLES";
export const SET_STYLE_GROUPS = "SET_STYLE_GROUPS";
export const SET_SELECTED_STYLE = "SET_SELECTED_STYLE";
export const SET_FESTIVAL_CONFIG = "SET_FESTIVAL_CONFIG";
export const SET_BREWERY_LINEUP = "SET_BREWERY_LINEUP";
export const SET_BEER_LIST = "SET_BEER_LIST";
