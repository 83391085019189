import React, { Component } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import MetaTags from "react-meta-tags";

import Auxi from "../../../hoc/Auxi";
import classes from "./FestivalInfo.css";
import Loader from "../../../components/UI/Loader/Loader";
import FestivalInfoHeader from "./FestivalInfoHeader/FestivalInfoHeader";
import { withI18n } from "react-i18next";
import BreweryLineup from "./BreweryLineup/BreweryLineup";

class FestivalInfo extends Component {
    render() {
        const { t, config } = this.props;

        if (!this.props.festival) return <Loader />;

        return (
            <Auxi>
                <MetaTags>
                    <title>
                        {this.props.festival.name} | {t("info_title")} | Brewer
                        Stage
                    </title>
                    <meta
                        name="description"
                        content={t("info_title", {
                            festival: this.props.festival.name
                        })}
                    />
                </MetaTags>
                <FestivalInfoHeader festival={this.props.festival} />
                <div className={classes.TextContainer}>
                    <div>
                        {this.props.festival.info_text ? (
                            <ReactMarkdown
                                source={this.props.festival.info_text}
                            />
                        ) : null}
                    </div>
                    {config && config.enable_brewery_lineup && (
                        <React.Fragment>
                            <h3>{t("breweries_tab")}</h3>
                            <BreweryLineup
                                festivalId={this.props.festival.id}
                            />
                        </React.Fragment>
                    )}
                </div>
            </Auxi>
        );
    }
}

const mapStateToProps = state => {
    return {
        festival: state.festivalStage.currentFestival,
        config: state.festivalStage.currentFestivalConfig
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        null
    )(FestivalInfo)
);
