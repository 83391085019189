import React from 'react';
import classes from './Cards.css';

const animatedCard = (props) => {
    const { digit, animation } = props;
    return(
        <div className={ [classes.FlipCard, animation ].join(' ') }>
            <span>{digit}</span>
        </div>
    );
}

export default animatedCard;