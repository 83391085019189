import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./TopUsers.css";
import RankingItem from "../../../components/RankingItem/RankingItem";
import * as actions from "../../../store/actions/index";
import Message from "../../../components/UI/Message/Message";
import MetaTags from "react-meta-tags";
import RankingPlaceholder from "../RankingPlaceholder/RankingPlaceholder";
import { withI18n } from "react-i18next";

const USER_DEFAULT_IMAGE =
    "https://i1.wp.com/untappd.akamaized.net/site/assets/images/default_avatar_v2_gravatar.jpg";

class TopUsers extends Component {
    componentDidMount() {
        this.props.loadTopUsers(this.props.festivalId);
    }

    componentWillUnmount() {
        actions.unloadTopUsers(this.props.festivalId);
    }

    render() {
        const { t } = this.props;
        let users = null;

        if (!this.props.users) {
            users = <RankingPlaceholder loading />;
        } else if (this.props.users.length === 0) {
            users = <Message>{t("no_user_ranking")}</Message>;
        } else {
            users = this.props.users.slice(0, 20).map(user => {
                let user_img = user.img;
                if (!user_img) {
                    user_img = USER_DEFAULT_IMAGE;
                }
                const numBeers = user.beers_rated
                    ? Object.keys(user.beers_rated).length
                    : 0;
                return (
                    <RankingItem
                        position={user.position}
                        img={user_img}
                        default_img={USER_DEFAULT_IMAGE}
                        name={user.name}
                        key={user.name}
                        id={user.name}
                        subtitle={
                            user.num_checkins +
                            (user.num_checkins === 1
                                ? " check-in"
                                : " check-ins")
                        }
                        details={t("rated_beer_count", { count: numBeers })}
                        href={"https://untappd.com/user/" + user.name}
                        roundImage
                    />
                );
            });
        }

        return (
            <div className={classes.TopUsers}>
                <MetaTags>
                    <title>
                        {this.props.festival.name} | {t("top_users_title")} |
                        Brewer Stage
                    </title>
                    <meta
                        name="description"
                        content={t("top_users_desc", {
                            festival: this.props.festival.name
                        })}
                    />
                </MetaTags>
                {users}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.festivalStage.users,
        festival: state.festivalStage.currentFestival
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadTopUsers: id => dispatch(actions.loadTopUsers(id))
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TopUsers)
);
