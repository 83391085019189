import React, { Component } from "react";
import Gauge from "gaugeJS";
import classes from "./FestivalQualityChart.css";
import Auxi from "../../../../hoc/Auxi";

class FestivalQualityChart extends Component {
    componentDidMount() {
        var opts = {
            angle: 0.12, // The span of the gauge arc
            lineWidth: 0.26, // The line thickness
            radiusScale: 1, // Relative radius
            pointer: {
                length: 0.6, // // Relative to gauge radius
                strokeWidth: 0.04, // The thickness
                color: "#000000" // Fill color
            },
            percentColors: [
                [0.0, "#752700"],
                [0.5, "#007C74"],
                [1.0, "#0CC9BC"]
            ],
            renderTicks: {
                divisions: 5,
                divWidth: 1,
                divLength: 0.7,
                divColor: "rgba(255, 255, 255, 1)",
                subDivisions: 3,
                subLength: 0.5,
                subWidth: 0.5,
                subColor: "rgba(255, 255, 255, 0.5)"
            },

            generateGradient: true,
            highDpiSupport: true // High resolution support
        };
        var gauge = new Gauge.Gauge(this.refs.chart).setOptions(opts); // create sexy gauge!
        gauge.maxValue = 5; // set max gauge value
        gauge.setMinValue(0); // Prefer setter over gauge.minValue = 0
        gauge.animationSpeed = 32; // set animation speed (32 is default value)
        gauge.set(this.props.ratingScore); // set actual value
    }

    render() {
        return (
            <Auxi>
                <div className={classes.Score}>
                    {this.props.ratingScore.toFixed(2)}
                </div>
                <canvas className={classes.Canvas} ref="chart" />
            </Auxi>
        );
    }
}

export default FestivalQualityChart;
