import React, { Component } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import classes from "./OldFestivalList.css";
import Loader from "../../components/UI/Loader/Loader";
import * as actions from "../../store/actions/index";
import { withI18n } from "react-i18next";

class OldFestivalList extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.months = [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may_full"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december")
        ];
    }

    componentDidMount() {
        this.props.loadOldFestivalList();
        this.props.exitFestival();
    }

    festivalSelectedHandler = id => {
        this.props.history.push({ pathname: "/festival/" + id });
    };

    render() {
        const { t } = this.props;
        let festlist = <Loader />;

        let yearCount = null;

        if (this.props.festivals) {
            festlist = this.props.festivals
                .slice(0, 20)
                .map((festival, index) => {
                    // Get day and month
                    let day = t("tba");
                    let month = "";
                    let year = festival.start_date.substring(0, 4);
                    let yearLabel = null;
                    if (year !== yearCount) {
                        yearCount = year;
                        yearLabel = (
                            <div className={classes.Year}>{yearCount}</div>
                        );
                    }
                    if (festival.start_date) {
                        day = festival.start_date.substring(8, 10);
                        if (festival.end_date) {
                            day += " - " + festival.end_date.substring(8, 10);
                        }
                        month = festival.start_date.substring(5, 7);
                        month = this.months[parseInt(month, 10) - 1];
                    }
                    return (
                        <div key={festival.id}>
                            {yearLabel}
                            <div
                                className={classes.Festival}
                                onClick={() =>
                                    this.festivalSelectedHandler(festival.id)
                                }
                            >
                                {festival.name}
                                <div className={classes.Info}>
                                    <strong>{festival.location}</strong>
                                    <div>
                                        {day} {month}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
        }

        return (
            <div className={classes.FestivalList}>
                <MetaTags>
                    <title>{t("old_festivals_title")} | Brewer Stage</title>
                    <meta
                        name="description"
                        content={t("old_festivals_desc")}
                    />
                </MetaTags>
                {festlist}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        festivals: state.festivalList.oldFestivals
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadOldFestivalList: () => dispatch(actions.loadOldFestivalList()),
        exitFestival: () => dispatch(actions.exitFestival())
    };
};

export default withI18n()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OldFestivalList)
);
