import React, { Component } from "react";
import classes from "./CountdownTimer.css";
import FlipUnitContainer from "./FlipUnitContainer/FlipUnitContainer";
import { withI18n } from "react-i18next";

class CountdownTimer extends Component {
    countdown() {
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = this.props.date.getTime() - now;

        // Time calculations for days, hours, minutes and seconds
        return {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000)
        };
    }

    constructor(props) {
        super(props);
        const countdown = this.countdown();
        this.state = {
            days: countdown.days,
            daysShuffle: true,
            hours: countdown.hours,
            hoursShuffle: true,
            minutes: countdown.minutes,
            minutesShuffle: true,
            seconds: countdown.seconds,
            secondsShuffle: true,
            expired: false
        };
    }

    componentWillMount() {
        this.timerId = setInterval(() => {
            if (new Date() > this.props.date) {
                this.setState({ expired: true });
                return;
            }
            const countdown = this.countdown();
            this.setState(prevState => {
                return {
                    days: countdown.days,
                    daysShuffle:
                        prevState.days === countdown.days
                            ? prevState.daysShuffle
                            : !prevState.daysShuffle,
                    hours: countdown.hours,
                    hoursShuffle:
                        prevState.hours === countdown.hours
                            ? prevState.hoursShuffle
                            : !prevState.hoursShuffle,
                    minutes: countdown.minutes,
                    minutesShuffle:
                        prevState.minutes === countdown.minutes
                            ? prevState.minutesShuffle
                            : !prevState.minutesShuffle,
                    seconds: countdown.seconds,
                    secondsShuffle:
                        prevState.seconds === countdown.seconds
                            ? prevState.secondsShuffle
                            : !prevState.secondsShuffle
                };
            });
        }, 50);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render() {
        const { t } = this.props;

        if (this.state.expired) {
            return <div />;
        }

        return (
            <div className={classes.Countdown}>
                <div className={classes.Labels}>
                    <span>{t("countdown_days")}</span>
                    <span>{t("countdown_hours")}</span>
                    <span>{t("countdown_minutes")}</span>
                    <span>{t("countdown_seconds")}</span>
                </div>
                <div className={classes.FlipClock}>
                    <FlipUnitContainer
                        unit={"days"}
                        digit={this.state.days}
                        shuffle={this.state.daysShuffle}
                    />
                    <FlipUnitContainer
                        unit={"hours"}
                        digit={this.state.hours}
                        shuffle={this.state.daysShuffle}
                    />
                    <FlipUnitContainer
                        unit={"minutes"}
                        digit={this.state.minutes}
                        shuffle={this.state.minutesShuffle}
                    />
                    <FlipUnitContainer
                        unit={"seconds"}
                        digit={this.state.seconds}
                        shuffle={this.state.secondsShuffle}
                    />
                </div>
            </div>
        );
    }
}

export default withI18n()(CountdownTimer);
