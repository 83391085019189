import React from "react";
import classes from "./DrawerToggle.css";
import { connect } from "react-redux";

const drawerToggle = props => {
    let drawerToggle = (
        <div className={classes.DrawerToggle} onClick={props.click}>
            <div />
            <div />
            <div />
        </div>
    );
    return drawerToggle;
};

const mapStateToProps = state => {
    return {
        currentFestival: state.festivalStage.currentFestival
    };
};

export default connect(mapStateToProps)(drawerToggle);
