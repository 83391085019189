import * as actionTypes from "../actions/actionTypes";

const initialState = {
    showSideDrawer: false,
    error: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_SIDE_DRAWER:
            return {
                ...state,
                showSideDrawer: true
            };
        case actionTypes.CLOSE_SIDE_DRAWER:
            return {
                ...state,
                showSideDrawer: false
            };
        case actionTypes.SET_ERROR_STATE:
            return {
                ...state,
                error: action.error
            };
        case actionTypes.CLEAR_ERROR_STATE:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export default reducer;
